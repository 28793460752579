import ApplicationResource from "@/models/ApplicationResource"

import type Domain from "@/models/Domain"
import type User from "@/models/User"

import LoginApi from "@/api/login_api"

const API = new LoginApi()

export default class Login extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare meta: ApplicationResource["meta"] & {
    permissions?: ResourcePermission[]
  }

  declare confirmed_at?: string
  declare current_sign_in_at?: string
  declare email?: string
  declare global_role_names?: string[]
  declare last_sign_in_at?: string
  declare password?: string
  declare password_confirmation?: string
  declare planned_email?: string
  declare privacy_accepted?: boolean
  declare provider?: string
  declare shibboleth_id?: string
  declare terms_accepted?: boolean
  declare user_id?: string

  static readonly associations = {
    manage_domains: {
      type: "many",
      sort: (a: Domain, b: Domain) =>
        (a.name || "").localeCompare(b.name || ""),
    },
    real_manage_domains: {
      type: "many",
      sort: (a: Domain, b: Domain) =>
        (a.name || "").localeCompare(b.name || ""),
    },
    regular_admin_domains: {
      type: "many",
      sort: (a: Domain, b: Domain) =>
        (a.name || "").localeCompare(b.name || ""),
    },
    team_user_admin_domains: {
      type: "many",
      sort: (a: Domain, b: Domain) =>
        (a.name || "").localeCompare(b.name || ""),
    },
    user: {
      type: "single",
    },
  }

  declare manage_domains: Domain[]
  declare real_manage_domains: Domain[]
  declare regular_admin_domains: Domain[]
  declare team_user_admin_domains: Domain[]
  declare user?: User

  hasPermission(permission_name: ResourcePermission) {
    return this.meta.permissions?.includes(permission_name)
  }

  isGuest() {
    return this.user?.isGuest()
  }

  isTeam() {
    return this.user?.type == "TeamUser"
  }
}
