import Qs from "qs"
import type { AxiosProgressEvent, AxiosResponse } from "axios"

import axios from "@/axios"
import config from "@/config"
import serializer from "@/api/serializer"
import { serialize as objectToFormData } from "object-to-formdata"
import sanitize from "sanitize-filename"
import { saveAs } from "file-saver"

import { deleteBlankKeys } from "@/utils/object_utils"

import type ApplicationResource from "@/models/ApplicationResource"
import type JobLogEntry from "@/models/JobLogEntry"

type curResourceProperties = ResourcePropertiesOf<ApplicationResource>

export default abstract class ResourceApi<T extends curResourceProperties> {
  namespace = ""
  type = ""
  serializer = serializer
  axios = axios

  serializeParams(params: unknown): string {
    return Qs.stringify(params, { arrayFormat: "brackets" })
  }

  apiPath({
    scope,
    format,
    params,
  }: {
    scope?: string
    format?: string
    params?: Record<string, unknown>
  } = {}) {
    let path = scope ? `${this.namespace}/${scope}` : this.namespace

    if (format) {
      path = `${path}.${format}`
    }

    if (params) {
      path = `${path}?${this.serializeParams(params)}`
    }

    return path
  }

  apiUrl(...args: Parameters<ResourceApi<ApplicationResource>["apiPath"]>) {
    return `${config.api_endpoint}/${this.apiPath(...args)}`
  }

  protected addCollectionParamsToObject(
    params: APIResourceCollectionParams,
    obj: any,
  ) {
    Object.assign(obj, {
      page: {
        number: params.page,
        size: params.size,
      },
      filter: { ...params.filter },
      scope_filter: { ...params.scopeFilter },
    })

    if (obj.page) {
      deleteBlankKeys(obj.page)
    }

    if (obj.filter) {
      deleteBlankKeys(obj.filter)
    }

    if (obj.scope_filter) {
      deleteBlankKeys(obj.scope_filter)
    }

    if (params.include) {
      obj.include = [...params.include]
    }

    if (params.includeAllForms) {
      const formName =
        params.includeAllForms === true ? "form" : params.includeAllForms
      obj.include = obj.include || []
      obj.include.push(
        `${formName}.form_inputs.real_form_input_options`,
        `${formName}.sub_forms.form_inputs.real_form_input_options`,
        `${formName}.sub_forms.sub_forms.form_inputs.real_form_input_options`,
        `${formName}.sub_forms.sub_forms.sub_forms.form_inputs.real_form_input_options`,
      )
    }

    if (params.sort) {
      obj.sort = params.sort.join(",")
    }

    if (obj.include) {
      obj.include = obj.include.join(",")
    }
  }

  protected getFilenameFromResponse(res: AxiosResponse<any, any>) {
    if (res.headers["content-disposition"]) {
      const match =
        res.headers["content-disposition"].match(/filename="([^"]+)"/i)

      if (match) {
        return decodeURIComponent(match[1])
      }
    }

    if (res.headers["x-filename"]) {
      return res.headers["x-filename"]
    }
  }

  protected downloadBlob(data: Blob, filename: string) {
    saveAs(data, sanitize(filename, { replacement: "_" }))
  }

  async all({
    collectionParams,
    query,
    format,
  }: {
    collectionParams?: APIResourceCollectionParams
    query?: {}
    format?: string
  } = {}): Promise<APIAllResponse<T[]>> {
    const params = (query && { ...query }) || {}
    if (collectionParams) {
      this.addCollectionParamsToObject(collectionParams, params)
    }

    const res = await this.axios.get(this.apiPath({ format }), { params })

    let data = null
    if (res.headers["content-type"].startsWith("application/json")) {
      data = {
        data: this.serializer.deserialize(res.data) as T[],
        meta: res.data.meta,
      }
    } else {
      data = {
        data: res.data,
      } as any
    }

    return data
  }

  async allLastUpdated({
    collectionParams,
    query,
  }: {
    collectionParams?: APIResourceCollectionParams
    query?: {}
  } = {}): Promise<Date | null> {
    const params = (query && { last_updated: 1, ...query }) || {
      last_updated: 1,
    }
    if (collectionParams) {
      this.addCollectionParamsToObject(collectionParams, params)
    }

    const res = await this.axios.get(this.apiPath(), { params })
    return res.data?.data?.last_updated
      ? new Date(res.data.data.last_updated)
      : null
  }

  async allCount({
    collectionParams,
    query,
  }: {
    collectionParams?: APIResourceCollectionParams
    query?: {}
  } = {}): Promise<number> {
    const params = (query && { count: 1, ...query }) || {
      count: 1,
    }
    if (collectionParams) {
      this.addCollectionParamsToObject(collectionParams, params)
    }

    const res = await this.axios.get(this.apiPath(), { params })
    return res.data?.data?.count || 0
  }

  async startExport({
    collectionParams,
    query,
    format,
  }: {
    collectionParams?: APIResourceCollectionParams
    query?: {}
    format?: string
  } = {}): Promise<
    APIGetResponse<ResourcePropertiesOf<JobLogEntry>> | "no_content"
  > {
    const params = (query && { ...query }) || {}
    if (collectionParams) {
      this.addCollectionParamsToObject(collectionParams, params)
    }

    const res = await this.axios.post(
      this.apiPath({ scope: "export", format }),
      params,
    )

    if (res.status == 204) {
      return "no_content"
    }

    return {
      data: this.serializer.deserialize(
        res.data,
      ) as ResourcePropertiesOf<JobLogEntry>,
      meta: res.data.meta,
    }
  }

  async create({
    query,
    domain,
    attributes,
    relationships,
    relationshipPath,
    collectionParams,
    onProgress,
  }: {
    query?: { [x: string]: unknown }
    domain?: string
    attributes?: any
    relationships?: any
    relationshipPath?: Array<{ id?: string; name: string }>
    collectionParams?: APIResourceCollectionParams
    onProgress?: (x: AxiosProgressEvent) => unknown
  } = {}): Promise<T> {
    const data: any = {
      type: this.type,
    }

    query = (query && { ...query }) || {}
    this.addRelationshipParamsToQuery(relationshipPath, query)

    if (collectionParams) {
      this.addCollectionParamsToObject(collectionParams, query)
    }

    const serializedData: any = this.serializer.serialize(data)

    if (domain) {
      serializedData.domain_id = domain
    }

    if (attributes) {
      serializedData.data.attributes = attributes
    }

    if (relationships) {
      serializedData.data.relationships = relationships
    }

    const res = await this.axios.post(
      this.apiPath(),
      objectToFormData(serializedData),
      {
        params: query,
        onUploadProgress: onProgress,
      },
    )

    return this.serializer.deserialize(res.data) as T
  }

  async get(
    id: string,
    {
      collectionParams,
      query,
    }: { collectionParams?: APIResourceCollectionParams; query?: {} } = {},
  ): Promise<APIGetResponse<T>> {
    const params = (query && { ...query }) || {}
    if (collectionParams) {
      this.addCollectionParamsToObject(collectionParams, params)
    }

    const res = await this.axios.get(this.apiPath({ scope: id }), {
      params: { ...params },
    })

    return {
      data: this.serializer.deserialize(res.data) as T,
      meta: res.data.meta,
    }
  }

  async getLastUpdated(
    id: string,
    { query }: { query?: {} } = {},
  ): Promise<Date | null> {
    const params = (query && { last_updated: 1, ...query }) || {
      last_updated: 1,
    }

    const res = await this.axios.get(this.apiPath({ scope: id }), { params })
    return res.data?.data?.last_updated
      ? new Date(res.data.data.last_updated)
      : null
  }

  addRelationshipParamsToQuery(
    relationshipPath: Array<{ id?: string; name: string }> | undefined,
    query: any,
  ) {
    if (relationshipPath && relationshipPath.length > 0) {
      query.parent = {
        id: relationshipPath[0].id,
        type: relationshipPath[0].name,
      }
      query.form_path = relationshipPath.slice(1)
    }
  }

  async update(
    id: string,
    name: string | null,
    value: unknown | null,
    {
      relationshipPath,
      query,
      onProgress,
      collectionParams,
      values,
    }: {
      relationshipPath?: Array<{ id?: string; name: string }>
      query?: { [x: string]: unknown }
      onProgress?: (x: AxiosProgressEvent) => unknown
      collectionParams?: APIResourceCollectionParams
      values?: any
    } = {},
  ) {
    const path = this.apiPath({ scope: id })

    query = (query && { ...query }) || {}
    this.addRelationshipParamsToQuery(relationshipPath, query)

    if (collectionParams) {
      this.addCollectionParamsToObject(collectionParams, query)
    }

    const data = {
      id,
      type: this.type,
    } as any

    if (name) {
      data.attributes = { [name]: value }
    } else if (values) {
      data.attributes = values
    }

    const res = await this.axios.put(
      path,
      { data },
      {
        params: query,
        onUploadProgress: onProgress,
      },
    )

    return {
      data: this.serializer.deserialize(res.data) as T,
      meta: res.data.meta,
    }
  }

  delete(
    id: string,
    {
      relationshipPath,
      query,
    }: {
      relationshipPath?: Array<{ id?: string; name: string }>
      query?: { [x: string]: unknown }
    } = {},
  ) {
    const path = this.apiPath({ scope: id })

    query = (query && { ...query }) || {}
    this.addRelationshipParamsToQuery(relationshipPath, query)

    return this.axios.delete(path, {
      data: {
        data: {
          type: this.type,
          id,
        },
      },
      params: query,
    })
  }

  async scrub(
    id: string,
    {
      query,
    }: {
      query?: { [x: string]: unknown }
    } = {},
  ): Promise<boolean> {
    const path = this.apiPath({ scope: `${id}/scrub` })

    await this.axios.delete(path, {
      data: {
        data: {
          type: this.type,
          id,
        },
      },
      params: query,
    })

    return true
  }

  async scrubScope(
    filter: {},
    {
      query,
    }: {
      query?: { [x: string]: unknown }
    } = {},
  ): Promise<boolean> {
    const path = this.apiPath({ scope: "scrub" })

    await this.axios.delete(path, {
      data: { filter },
      params: query,
    })

    return true
  }

  async createAttachment(
    id: string,
    property: string,
    value: File | File[],
    {
      query,
      onProgress,
      relationshipPath,
    }: {
      query?: { [x: string]: unknown }
      onProgress?: (x: AxiosProgressEvent) => unknown
      relationshipPath?: Array<{ id?: string; name: string }>
    } = {},
  ): Promise<T> {
    const path = this.apiPath({ scope: `${id}/attachments/${property}` })

    query = (query && { ...query }) || {}
    this.addRelationshipParamsToQuery(relationshipPath, query)

    const params: any = {
      data: {
        id,
        type: this.type,
        file: value,
      },
    }

    const serialized = objectToFormData(params)

    const res = await this.axios.post(path, serialized, {
      params: query,
      onUploadProgress: onProgress,
    })

    return this.serializer.deserialize(res.data) as T
  }

  async deleteAttachment(
    id: string,
    property: string,
    attachmentId: string,
    {
      query,
      onProgress,
      relationshipPath,
    }: {
      query?: { [x: string]: unknown }
      onProgress?: (x: AxiosProgressEvent) => unknown
      relationshipPath?: Array<{ id?: string; name: string }>
    } = {},
  ): Promise<T> {
    const path = this.apiPath({
      scope: `${id}/attachments/${property}/${attachmentId}`,
    })

    const data: any = {
      data: {
        id,
        type: this.type,
      },
    }

    query = (query && { ...query }) || {}
    this.addRelationshipParamsToQuery(relationshipPath, query)

    const res = await this.axios.delete(path, {
      data,
      params: query,
      onUploadProgress: onProgress,
    })

    return this.serializer.deserialize(res.data) as T
  }

  async duplicate(
    id: string,
    { query, domain }: { query?: {}; domain?: string } = {},
  ): Promise<APIGetResponse<T>> {
    const res = await this.axios.post(
      this.apiPath({ scope: `${id}/duplicate` }),
      {
        data: {
          type: this.type,
          id,
        },
        domain_id: domain,
      },
      { params: { query } },
    )

    return {
      data: this.serializer.deserialize(res.data) as T,
      meta: res.data.meta,
    }
  }

  async setRelationship(
    id: string,
    name: string,
    relationship: ResourceIdentifier | null,
    {
      query,
      relationshipPath,
    }: {
      relationshipPath?: Array<{ id?: string; name: string }>
      query?: { [x: string]: unknown }
    } = {},
  ): Promise<T | void> {
    const path = this.apiPath({ scope: `${id}/relationships/${name}` })

    query = (query && { ...query }) || {}
    this.addRelationshipParamsToQuery(relationshipPath, query)

    const res = await this.axios.patch(
      path,
      { data: relationship },
      { params: query },
    )

    if (res.data && res.data.data) {
      return this.serializer.deserialize(res.data) as T
    }
  }

  async addRelationship(
    id: string,
    name: string,
    relationship: ResourceIdentifier[],
    {
      query,
      relationshipPath,
    }: {
      relationshipPath?: Array<{ id?: string; name: string }>
      query?: { [x: string]: unknown }
    } = {},
  ): Promise<T[]> {
    const path = this.apiPath({ scope: `${id}/relationships/${name}` })

    query = (query && { ...query }) || {}
    this.addRelationshipParamsToQuery(relationshipPath, query)

    const res = await this.axios.post(
      path,
      { data: relationship },
      { params: query },
    )

    if (res.data && res.data.data) {
      return this.serializer.deserialize(res.data) as T[]
    } else {
      return []
    }
  }

  async removeRelationship(
    id: string,
    name: string,
    relationship: ResourceIdentifier[],
    {
      query,
      relationshipPath,
    }: {
      relationshipPath?: Array<{ id?: string; name: string }>
      query?: { [x: string]: unknown }
    } = {},
  ): Promise<T[]> {
    const path = this.apiPath({ scope: `${id}/relationships/${name}` })

    query = (query && { ...query }) || {}
    this.addRelationshipParamsToQuery(relationshipPath, query)

    const res = await this.axios.delete(path, {
      data: { data: relationship },
      params: query,
    })

    if (res.data && res.data.data) {
      return this.serializer.deserialize(res.data) as T[]
    } else {
      return []
    }
  }

  async order(
    resource_to_move_id: string,
    resource_to_move_after_id: string | null,
    {
      query,
      relationshipPath,
    }: {
      query?: { [x: string]: unknown }
      relationshipPath?: Array<{ id?: string; name: string }>
    } = {},
  ): Promise<{ order: string[] }> {
    const path = this.apiPath({ scope: `${resource_to_move_id}/order` })

    query = (query && { ...query }) || {}
    this.addRelationshipParamsToQuery(relationshipPath, query)

    const res = await this.axios.put(
      path,
      {
        data: {
          id: resource_to_move_id,
          type: this.type,
          move_after_id: resource_to_move_after_id,
        },
      },
      { params: query },
    )

    return res.data.data
  }
}
