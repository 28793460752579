import ApplicationResource from "./ApplicationResource"

import Api from "@/api/last_seen_entry_api"

const API = new Api()

export default class LastSeenEntry extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare context?: string
  declare last_seen_at?: string

  static readonly associations = {
    resource: {
      type: "single",
    },
  }
}
