import ResourceApi from "./resource_api"

import type PdfSetting from "@/models/PdfSetting"
type curResourceProperties = ResourcePropertiesOf<PdfSetting>

export default class PdfSettingApi extends ResourceApi<curResourceProperties> {
  namespace = "pdf_settings"
  type = "pdf_setting"

  async preview(id: string) {
    window.open(
      this.apiUrl({
        scope: `${id}/preview`,
        format: "pdf",
      }),
    )
  }
}
