import Vue from "vue"
import { ActionTree, MutationTree, Module } from "vuex"

import { dig } from "@/utils/object_utils"

import { RootState } from "@/store/config"

import SettingsApi from "@/api/settings_api"

const filesSettings = [
  "congeno_logo",
  "congeno_mobile_logo",
  "customer_header_logo",
  "customer_header_mobile_logo",
  "certificates_banner",
  "certificates_font_bold",
  "certificates_font_regular",
  "certificates_signature",
  "favicon_svg",
  "welcome_banner_de",
  "welcome_banner_en",
  "welcome_mobile_banner_de",
  "welcome_mobile_banner_en",
]

export interface State {
  _fetching?: string[]
  access_token_login_finish?: {
    name: string
  }
  certificates?: {
    enabled: boolean
    namespace?: string
    generator: "prawn" | "weasy"
    facts: string[]
    templates: Array<{
      key: string
      label: string
      only_for_domains?: string[]
    }>
  }
  certificates_banner?: FileResource
  certificates_banner_settings?: {
    height: number
  }
  certificates_font_bold?: FileResource
  certificates_font_regular?: FileResource
  certificates_font_leading?: number
  certificates_font_size?: number
  certificates_signature?: FileResource
  certificates_signature_settings?: {
    height: number
  }
  congeno_logo?: FileResource
  congeno_mobile_logo?: FileResource
  congeno_logo_settings?: {
    align?: string
    height: number
    link?: string
  }
  congeno_mobile_logo_settings?: {
    align?: string
    height: number
    link?: string
  }
  congeno_colors?: {
    fontColor?: string
    linkColor?: string
    primary?: string
    alt?: string
    highlight?: string
    textPrimary?: string
    textAlt?: string
    textHighlight?: string
  }
  create_account_users?: {
    enabled: boolean
  }
  create_team_users?: {
    enabled: boolean
  }
  customer_header_logo?: FileResource
  customer_header_mobile_logo?: FileResource
  customer_header_logo_settings?: {
    align?: string
    height: number
    link?: string
  }
  customer_header_mobile_logo_settings?: {
    align?: string
    height: number
    link?: string
  }
  domains?: {
    enabled: boolean
  }
  event?: {
    allow_cancel: boolean
    application_restriction: boolean
    application_status_color: boolean
    custom_event_application_form: boolean
    edit_signatures: boolean
    evaluation: boolean
    num_appointmens_to_display_directly_in_table: number
    pickable_for_other_places_default: boolean
    local_mail_templates: boolean
    logo_in_table: {
      enabled: boolean
      width: number
    }
  }
  event_add_users_manually?: {
    enabled: boolean
    methods: string[]
  }
  event_application?: {
    apply: Array<"with_account" | "without_account" | "with_booking_number">
    apply_default: Array<
      "with_account" | "without_account" | "with_booking_number"
    >
    apply_again_location: string
    apply_decider_options: Array<
      | "with_new_account"
      | "with_existing_account"
      | "with_booking_number"
      | "without_account"
    >
    enabled: boolean
    export_template_required: boolean
    participation: boolean
    pdf_export_enabled: boolean
    send_ical_with_application: boolean
    show_all_domains_on_application_list: boolean
  }
  event_automatic_scrubbing?: {
    enabled: boolean
    after_days: number
  }
  event_edit_text_contents?: {
    enabled: boolean
  }
  event_invoices?: {
    enabled: boolean
  }
  event_mail_task_schedule?: {
    event_reminder: {
      use_case: string
      offset: number
      offset_column: string
      mailer: string
      recipient_finder: string
      recipient_finder_association_name: string
      recipient_finder_filter: {
        [x: string]: string | string[]
      }
    }
  }
  event_series?: {
    enabled: boolean
  }
  favicon_svg?: FileResource
  font?: {
    family?: string
    size?: string
    line_height?: string | number
  }
  home?: {
    name: string
    params?: {}
  }
  inputs?: {
    color: {
      extra_colors: string[]
      default_colors: string[]
    }
  }
  invoices?: {
    prefix: string
    generator: "prawn" | "weasy"
    templates: Array<{
      key: string
      label: string
      only_for_domains?: string[]
    }>
  }
  jitsi?: {
    enabled: boolean
  }
  locales?: {
    default: string
    available: string[]
  }
  mail_rate_limit?: {
    seconds: number
    threshold: number
    min_delay_multiplier: number
    max_delay_multiplier: number
  }
  mail_rate_limit_system?: {
    seconds: number
    threshold: number
    min_delay_multiplier: number
    max_delay_multiplier: number
  }
  newsletter?: {
    enabled: boolean
    layout?: string
    layout_toc_mail?: string
  }
  pages?: {
    enabled: boolean
  }
  places?: {
    enabled: boolean
  }
  public_events?: {
    num_active_applications: boolean
    slug_path_name: string
    tabs: Record<
      string,
      | undefined
      | {
          scope: Record<string, unknown>
          label: {
            de: string
            en: string
          }
        }
    >
  }
  polls?: {
    enabled: boolean
  }
  public_sidebar?: {
    login: boolean
    events: boolean
    event_series: boolean
    pages: boolean
    polls: boolean
  }
  registration?: {
    enabled: boolean
  }
  rich_text?: {
    accordion: {
      enabled: boolean
    }
    image: {
      enabled: boolean
    }
    upload: {
      enabled: boolean
    }
  }
  router_slugs?: {
    event: string
    events: string
    my_events: string
  }
  shibboleth?: {
    idp: { [x: string]: string }
    path: string
  }
  signatures?: {
    enabled: boolean
    max_num: number
  }
  signin_methods?: Array<
    "local" | "shibboleth" | "booking_number" | "access_token" | "ask_for_local"
  >
  title?: string
  universities?: {
    enabled: boolean
  }
  uploads?: {
    enabled: boolean
  }
  user?: {
    export: boolean
    taggable: boolean
  }
  user_data_confirmation?: {
    enabled: boolean
    recurrence: {
      [x: string]: unknown
    }
  }
  user_custom_group_keys?: string[]
  user_custom_salutation?: {
    enabled: boolean
  }
  user_save_search?: {
    enabled: boolean
  }
  users_statistic?: {
    enabled: boolean
    base_scope: Record<string, string>
  }
  welcome_banner_de?: FileResource
  welcome_banner_en?: FileResource
  welcome_mobile_banner_de?: FileResource
  welcome_mobile_banner_en?: FileResource
}

export const STATE: State = {}

export const MUTATIONS: MutationTree<State> = {
  assign(state: State, object: Partial<State>) {
    for (const key in object) {
      Vue.set(state, key, (object as any)[key])
    }
  },
  updateKey(state: State, { key, value }: { key: string; value: unknown }) {
    if (key.includes(".")) {
      const split = key.split(".")
      const existing = dig(state, split.slice(0, -1))
      Vue.set(existing, split[split.length - 1], value)
    } else {
      Vue.set(state, key, value)
    }
  },
}

export const ACTIONS: ActionTree<State, RootState> = {
  async fetch({ commit, state }, keys: Array<keyof State>) {
    const present = Object.keys(state)
    const missing = keys.filter(
      (el) => !present.includes(el) && !state._fetching?.includes(el),
    )
    if (missing.length > 0) {
      commit("assign", { _fetching: [...missing, ...(state._fetching || [])] })

      const missingFiles = missing.filter((key) => filesSettings.includes(key))
      const missingRegular = missing.filter(
        (key) => !filesSettings.includes(key),
      )

      const settingsToSet: Record<string, unknown> = {}
      if (missingRegular.length > 0) {
        Object.assign(settingsToSet, await SettingsApi.get(missingRegular))
      }

      if (missingFiles.length > 0) {
        Object.assign(
          settingsToSet,
          await SettingsApi.getAttachments(missingFiles),
        )
      }

      commit("assign", settingsToSet)
    }

    return state
  },

  async update(
    { commit, state },
    { key, value }: { key: keyof State; value: unknown },
  ) {
    if (filesSettings.includes(key)) {
      value = await SettingsApi.updateAttachment(key, value)
    } else {
      SettingsApi.update(key, value)
    }

    commit("updateKey", { key, value })

    return state
  },
}

const MODULE: Module<State, RootState> = {
  namespaced: true,
  state: STATE,
  mutations: MUTATIONS,
  actions: ACTIONS,
}

export default MODULE
