import Vue from "vue"
import { MutationTree, ActionTree, Module } from "vuex"

import { deleteAllKeys } from "@/utils/object_utils"
import { deferred } from "@/utils/promise_utils"

import { RootState } from "@/store/config"

import Tag from "@/models/Tag"

export interface State {
  tags: {
    [id: string]: Tag | undefined
  }
  promises: {
    [id: string]: Promise<Tag> | undefined
  }
  requested: Set<string>
}

export const STATE: State = {
  tags: {},
  promises: {},
  requested: new Set(),
}

export const MUTATIONS: MutationTree<State> = {
  clear(state: State) {
    deleteAllKeys(state.tags)
  },
  setTag(state: State, { key, tag }: { key: string; tag: Tag }) {
    Vue.set(state.tags, key, tag)
  },
  setPromise(state: State, { key, prom }: { key: string; prom: Promise<Tag> }) {
    Vue.set(state.promises, key, prom)
  },
  addRequested(state: State, key: string) {
    state.requested.add(key)
  },
  removeRequested(state: State, key: string) {
    state.requested.delete(key)
  },
}

export const ACTIONS: ActionTree<State, RootState> = {
  async fetchTag(
    { commit, dispatch, state },
    key: string,
  ): Promise<Tag | undefined> {
    if (state.requested.has(key)) {
      if (state.tags[key]) {
        return state.tags[key]
      } else {
        return state.promises[key]!
      }
    } else {
      commit("addRequested", key)

      const def = deferred()
      commit("setPromise", { key, prom: def.promise })

      try {
        const tag = await dispatch("getTag", key)
        def.resolve(tag)
        return tag
      } catch (e: any) {
        commit("removeRequested", key)
        def.reject(e)
        return undefined
      }
    }
  },
  async getTag({ commit }, key: string) {
    const resp = await Tag.api.all({
      collectionParams: { filter: { system_purpose: key } },
    })

    const data = resp.data[0]

    if (!data) {
      return
    }

    const tag = new Tag(data)

    commit("setTag", {
      key,
      tag,
    })

    return tag
  },
}

const Tags: Module<State, RootState> = {
  namespaced: true,
  state: STATE,
  mutations: MUTATIONS,
  actions: ACTIONS,
}

export default Tags
