import Vue from "vue"

export default Vue.extend({
  computed: {
    actionsWithLabel(): ActionOption[] {
      const actionsCore: string[] = (this as any).actions
      if (!actionsCore) {
        return []
      }

      return this.actionsToActionOptions(actionsCore)
    },
  },
  methods: {
    actionsToActionOptions(cores: string[]): ActionOption[] {
      return cores.map((key) => {
        return { label: this.$curT(`actions.${key}`) as string, key }
      })
    },
  },
})
