import { MutationTree, Module } from "vuex"
import { make } from "vuex-pathify"

import { RootState } from "@/store/config"

export interface State {
  available: boolean
  hidden: boolean
}

export const STATE: State = {
  available: true,
  hidden: false,
}

export const MUTATIONS: MutationTree<State> = make.mutations(STATE)

const Sidebar: Module<State, RootState> = {
  namespaced: true,
  state: STATE,
  mutations: MUTATIONS,
}

export default Sidebar
