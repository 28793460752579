import Vue from "vue"
import { make } from "vuex-pathify"

const state = {
  open: false,
  name: null,
  data: null,
  bus: new Vue(),
}

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  mutations,
}
