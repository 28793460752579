import ApplicationResource from "./ApplicationResource"
import type MailTaskSchedule from "./MailTaskSchedule"
import type MailTaskStatistics from "@/models/MailTaskStatistics"
import type MailTemplate from "./MailTemplate"
import type MailTemplateUseCase from "./MailTemplateUseCase"
import type Search from "./Search"

import MailTaskApi from "@/api/mail_task_api"

const API = new MailTaskApi()

export default class MailTask extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare meta: ApplicationResource["meta"] & {
    applied_permissions?: Array<
      ResourceAppliedPermission | "cancel" | "reschedule"
    >
  }

  declare author_name?: string
  declare bucket_for?: string
  declare bucket_for_de?: string
  declare bucket_for_en?: string
  declare recurrent?: boolean
  declare resource_id?: string
  declare resource_type?: string
  declare sent_at?: string
  declare status?: string
  declare status_de?: string
  declare status_en?: string

  static readonly associations = {
    mail_template: {
      type: "single",
    },
    mail_template_to_send: {
      type: "single",
    },
    mail_task_schedule: {
      type: "single",
    },
    mail_template_use_case: {
      type: "single",
    },
    resource: {
      type: "single",
    },
    search: {
      type: "single",
    },
    statistic: {
      type: "single",
    },
  }

  declare statistic?: MailTaskStatistics
  declare mail_task_schedule?: MailTaskSchedule
  declare mail_template?: MailTemplate
  declare mail_template_to_send?: MailTemplate
  declare mail_template_use_case?: MailTemplateUseCase
  declare resource?: ApplicationResource
  declare search?: Search
}
