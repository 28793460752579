import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"

import Api from "@/api/mail_picture_api"

const API = new Api()

export default class MailPicture extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  static readonly fileResources: string[] = ["file"]

  declare bgcolor?: string
  declare copyright?: string
  declare file?: FileResource
  declare order?: number
  declare position?: string
  declare variant?: { url: string }
  declare width?: number

  static readonly associations = {
    form: {
      type: "single",
    },
  }

  declare form?: Form
}
