import { camelCase, upperFirst } from "lodash-es"

const context = require.context("@/models")
const classMap = {} as { [x: string]: any }

export function classForType(type: string) {
  if (!type) {
    console.error("Missing type")
    return
  }

  const existing = classMap[type]
  if (existing) {
    return existing
  }

  const toResolve = `./${upperFirst(camelCase(type))}`

  if (!context.keys().includes(toResolve)) {
    return
  }

  const klass = context(toResolve)?.default
  classMap[type] = klass
  return klass
}
