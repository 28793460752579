import Vue from "vue"
Vue.prototype.$window = window

// external stuff //

import AsyncComputed from "vue-async-computed"
Vue.use(AsyncComputed)

import VTooltip from "v-tooltip"
Vue.use(VTooltip)

import VueFlashMessage from "vue-flash-message"
Vue.use(VueFlashMessage, {
  createShortcuts: false,
  messageOptions: { timeout: 6000, pauseOnInteract: true },
})

import vSelect from "vue-select"
Vue.component("VSelect", vSelect)
import "vue-select/dist/vue-select.css"

import VueDomPurifyHTML from "vue-dompurify-html"
Vue.use(VueDomPurifyHTML, {
  default: {
    USE_PROFILES: { html: true },
    ADD_ATTR: ["target"],
  },
  namedConfigurations: {
    svg: {
      USE_PROFILES: { svg: true },
    },
  },
})

import Vue2TouchEvents from "vue2-touch-events"
Vue.use(Vue2TouchEvents)

Vue.directive("mask", {
  async bind(el: HTMLElement, binding: any) {
    const Inputmask = (await import("inputmask")).default
    Inputmask(binding.value).mask(el)
  },
})

Vue.directive("autosize", {
  async bind(el) {
    const autosize = (await import("autosize")).default
    autosize(el)
    setTimeout(() => autosize.update(el), 0)
  },
})

Vue.directive("autosize-input", {
  async bind(el) {
    const autosize = (await import("autosize-input")).default
    autosize(el)
  },
})

Vue.directive("sticky", {
  async bind(el: HTMLElement, binding: any) {
    el.classList.add("sticky-element")

    Vue.nextTick(() => {
      const offset = binding.value || -40

      const observer = new IntersectionObserver(
        ([e]) => {
          e.target.classList.toggle("is-sticky", e.intersectionRatio == 1)
        },
        {
          root: el.parentElement,
          threshold: 1.0,
          rootMargin: `${offset}px 0px 0px 0px`,
        },
      )

      observer.observe(el)
    })
  },
})

import { directive } from "v-click-outside"
Vue.directive("click-outside", directive)

import "@fontsource/open-sans/latin.css"
import "@fontsource/fira-sans/latin.css"
import "@fontsource/mulish/latin.css"

// local stuff //

import i18n, { setLocale, setDefaultLocale, setAllowedLocales } from "@/i18n"
import { useStore } from "@/store"
import { useRouter } from "@/router"

import AuthPlugin from "@/plugins/auth"
Vue.use(AuthPlugin)

import FormsPlugin from "@/plugins/forms"
Vue.use(FormsPlugin)

import SettingsPlugin from "@/plugins/settings"
Vue.use(SettingsPlugin)

import I18nMixin from "@/mixins/i18n"
Vue.mixin(I18nMixin)

import ActionsWithLabelMixin from "@/mixins/actions_with_label"
Vue.mixin(ActionsWithLabelMixin)

import SortOptionsWithLabelMixin from "@/mixins/sort_options_with_label"
Vue.mixin(SortOptionsWithLabelMixin)

import TitleMixin from "@/mixins/title"
Vue.mixin(TitleMixin)

Vue.component("YoutubeVideo", () => import("@/components/YoutubeVideo.vue"))

import "@/fontAwesome"
import "@/markdown"

import App from "@/App.vue"

Vue.config.productionTip = false

const initVue = async () => {
  const $store = useStore()

  await Promise.all([
    $store.dispatch("auth/checkSelf"),
    $store.dispatch("settings/fetch", [
      "congeno_colors",
      "congeno_logo",
      "congeno_logo_settings",
      "congeno_mobile_logo",
      "congeno_mobile_logo_settings",
      "customer_header_logo",
      "customer_header_logo_settings",
      "customer_header_mobile_logo",
      "customer_header_mobile_logo_settings",
      "domains",
      "event",
      "event_invoices",
      "event_series",
      "favicon_svg",
      "font",
      "home",
      "jitsi",
      "locales",
      "newsletter",
      "places",
      "polls",
      "public_events",
      "public_sidebar",
      "registration",
      "router_slugs",
      "signatures",
      "signin_methods",
      "title",
      "universities",
      "welcome_banner_de",
      "welcome_banner_en",
      "welcome_mobile_banner_de",
      "welcome_mobile_banner_en",
    ]),
  ])

  setLocale($store.state.settings.locales!.default)
  setDefaultLocale($store.state.settings.locales!.default)
  setAllowedLocales($store.state.settings.locales!.available)

  new Vue({
    name: "Root",
    store: $store,
    router: useRouter(),
    i18n,
    render: (h) => h(App),
  }).$mount("#app")
}

initVue()
