<template>
  <div id="app" :key="$parent.key">
    <flash-message transition-name="list" />
    <TopBar v-if="showTopBar" />
    <div id="wrapper">
      <SideBar
        :is="sidebar"
        v-if="sidebarAvailable"
        id="sidebar"
        ref="sidebar"
        v-touch:swipe.left="closeSidebar"
      />
      <div id="content">
        <main id="main" :class="mainClass" @click="sidebarHidden = true">
          <router-view
            ref="view"
            @site-title="setSiteTitle"
            @site-title-params="setSiteTitleParams"
          />
        </main>
        <FooterComponent
          v-if="showFooter"
          ref="footer"
          :class="mainClass"
          :link-target="$route.meta.linksNewWindow ? '_blank' : null"
        />
        <aside v-if="showPoweredBy" :class="mainClass" class="powered-by">
          <div class="congeno">
            <a
              v-if="$settings.congeno_logo"
              :href="$settings.congeno_logo_settings.link"
              target="_blank"
            >
              <img
                v-if="$settings.congeno_logo"
                class="congeno-logo"
                :src="$settings.congeno_logo.url"
                :style="{
                  height: $settings.congeno_logo_settings.height
                    ? $settings.congeno_logo_settings.height + 'px'
                    : false,
                }"
              />
            </a>
            <a
              v-else-if="$settings.congeno_mobile_logo"
              :href="$settings.congeno_logo_settings.link"
              target="_blank"
            >
              <img
                v-if="$settings.congeno_mobile_logo"
                class="congeno-logo"
                :src="$settings.congeno_mobile_logo.url"
                :style="{
                  height: $settings.congeno_mobile_logo_settings.height
                    ? $settings.congeno_mobile_logo_settings.height + 'px'
                    : false,
                }"
              />
            </a>
          </div>
        </aside>
        <MultipleAppointmentsModal
          v-if="modalOpen && modalName == 'MultipleAppointmentsModal'"
          @close="modalOpen = false"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import { get, sync } from "vuex-pathify"

import { forceAllUpdate } from "@/utils/vue_utils"
import { hexToRgb } from "@/utils/color_utils"

import type { State as SettingsState } from "@/store/settings"

import type ApplicationResource from "@/models/ApplicationResource"

export default Vue.extend({
  name: "App",
  components: {
    FooterComponent: () => import("@/components/Footer.vue"),
    AdminSideBar: () => import("@/components/AdminSideBar.vue"),
    PublicSideBar: () => import("@/components/PublicSideBar.vue"),
    SideBar: () => import("@/components/SideBar.vue"),
    TopBar: () => import("@/components/TopBar.vue"),
    MultipleAppointmentsModal: () =>
      import("@/components/MultipleAppointmentsModal.vue"),
  },
  provide(): any {
    return {
      $app: this,
    }
  },
  data() {
    return {
      title: undefined as string | undefined,
      currentSiteTitle: undefined as string | undefined,
      currentSiteTitleParams: undefined as Record<string, string> | undefined,
      mobileBreakpoint: 900,
    }
  },
  computed: {
    documentTitle(): string[] {
      if (!this.title) {
        return []
      }

      const routeTitle: string[] =
        (this.$route.meta?.title as string[] | undefined) || []

      return [
        this.title,
        ...routeTitle.map((v) => {
          if (v == "$site") {
            return this.currentSiteTitle
          }

          if (v.startsWith("$")) {
            return this.currentSiteTitleParams
              ? this.currentSiteTitleParams[v]
              : undefined
          }

          return v[0] == "." ? this.$tc(`routeTitle${v}`, 2) : this.$tc(v, 2)
        }),
      ].filter((v) => v) as string[]
    },
    sidebar(): string {
      if (!this.$loginWithAccount || this.onlyPublicSidebar) {
        return "public-side-bar"
      }

      return this.showAdminSidebar ? "admin-side-bar" : "side-bar"
    },
    onlyPublicSidebar(): boolean {
      return this.$route.matched.some(
        (route) => route.meta.onlyPublicSidebar === true,
      )
    },
    showSidebar(): boolean {
      return !!(
        !this.adminArea &&
        !this.$route.matched.some((route) => route.meta.sidebar === false) &&
        ((this.$loginWithAccount && !this.onlyPublicSidebar) ||
          this.showPublicSidebar)
      )
    },
    showPublicSidebar(): boolean {
      return !!(
        this.$settings.public_sidebar?.login ||
        this.$settings.public_sidebar?.events ||
        this.$settings.public_sidebar?.event_series ||
        this.$settings.public_sidebar?.polls ||
        this.$settings.public_sidebar?.pages
      )
    },
    showAdminSidebar(): boolean {
      return !!(this.$loginWithAccount && this.adminArea)
    },
    showTopBar(): boolean {
      return !this.$route.matched.some((route) => route.meta.topBar === false)
    },
    showFooter(): boolean {
      return !this.$route.matched.some((route) => route.meta.footer === false)
    },
    showPoweredBy(): boolean {
      return this.$route.matched.some((route) => route.meta.poweredBy === true)
    },
    adminArea(): boolean {
      return this.$route.matched.some((route) => route.meta.adminArea)
    },
    mainClass(): any {
      const base: {
        full?: boolean
        narrow?: boolean
      } = {}

      if (this.$route.matched.some((route) => route.meta.fullContent)) {
        base.full = true
      }

      if (this.$route.matched.some((route) => route.meta.narrow)) {
        base.narrow = true
      }

      return base
    },
    sidebarAvailable: sync("sidebar/available"),
    sidebarHidden: sync("sidebar/hidden"),
    modalName: get("modal/name"),
    modalOpen: sync("modal/open"),
  },
  watch: {
    "$settings.favicon_svg"(val: FileResource | undefined) {
      if (!val?.url) {
        return
      }

      const link = document.getElementById("favSVG")

      if (!link || link.getAttribute("herf") == val.url) {
        return
      }

      link.setAttribute("type", "image/svg+xml")
      link.setAttribute("href", val.url)
    },
    "$settings.congeno_colors": {
      handler(colors: SettingsState["congeno_colors"]) {
        ;(this as any).setColors(colors)
      },
      deep: true,
    },
    "$settings.font": {
      handler(font: SettingsState["font"]) {
        ;(this as any).setFont(font)
      },
      deep: true,
    },
    "$settings.title": {
      handler(title: string) {
        if (!title) {
          return
        }

        this.title = title
      },
    },
    "$route.params.locale"(newVal: string, oldVal: any) {
      if (newVal != oldVal) {
        forceAllUpdate(this)
      }
    },
    "$route.name"() {
      if (!window.location.hash || window.location.hash.length < 2) {
        return
      }

      const id = window.location.hash.substring(1)

      if (!id) {
        return
      }

      const checkID = (id: string, count = 0) => {
        const el = document.getElementById(id)

        if (!el) {
          if (count >= 40) {
            return
          }

          setTimeout(() => checkID(id, count + 1), 250)
          return
        }

        setTimeout(() => {
          el.scrollIntoView()
        }, 500)
      }

      checkID(id)
    },
    documentTitle(val: string[] | undefined) {
      if (val?.length) {
        document.title = val.reverse().join(" · ")
      }
    },
    showSidebar(val: boolean) {
      ;(this as any).sidebarAvailable = val || this.showAdminSidebar
    },
    showAdminSidebar(val: boolean) {
      ;(this as any).sidebarAvailable = val || this.showSidebar
    },
  },
  beforeCreate() {
    this.$store.commit("initialiseStore")
  },
  created() {
    if (!this.$route.params.locale) {
      this.$route.params.locale = this.$i18n.fallbackLocale as string
    }

    ;(this as any).setTitle(this.$settings.title)
    ;(this as any).setColors(this.$settings.congeno_colors)
    ;(this as any).setFont(this.$settings.font)
    ;(this as any).sidebarAvailable = this.showSidebar || this.showAdminSidebar
  },
  methods: {
    globalAction(action: any, vars: Record<string, unknown> = {}) {
      if (action == "createNewRelationship") {
        const name = vars.name as string
        const resource = vars.resource as ApplicationResource

        if (!name || !resource) {
          return
        }

        if (resource._type == "user" && name == "contact_email_contact") {
          this.$router.push({
            name: "my_account_user_add_new_email",
            params: { id: resource.id },
            query: { change_login: "true" },
          })
        }
      }
    },
    closeSidebar() {
      ;(this as any).sidebarHidden = true
    },
    refreshSidebarCounts() {
      ;(this.$refs.sidebar as any)?.refreshCounts()
    },
    setTitle(title?: SettingsState["title"]) {
      if (title) {
        this.title = title
      }
    },
    setSiteTitle(title?: string) {
      this.currentSiteTitle = title
    },
    setSiteTitleParams(params?: {}) {
      this.currentSiteTitleParams = params
    },
    setColors(colors?: SettingsState["congeno_colors"]) {
      if (!colors) {
        return
      }
      Object.entries(colors).forEach(([key, val]) => {
        if (!val) {
          return
        }

        document.documentElement.style.setProperty(`--${key}`, val)

        const rgb = hexToRgb(val.slice(1))

        document.documentElement.style.setProperty(
          `--${key}-rgb`,
          `${rgb.r},${rgb.g},${rgb.b}`,
        )
      })
    },
    setFont(font?: SettingsState["font"]) {
      if (!font) {
        return
      }
      Object.entries(font).forEach(([key, val]) => {
        if (!val) {
          return
        }

        const capitalized = key.charAt(0).toUpperCase() + key.slice(1)

        document.documentElement.style.setProperty(
          `--font${capitalized}`,
          `${val}`,
        )
      })
    },
  },
})
</script>

<style lang="scss">
@import "@/assets/tailwind.css";

@import "~normalize.css";
@import "@/scss/normalize_inputs";
@import "@/scss/colors";
@import "@/scss/typography";
@import "@/scss/lists";
@import "@/scss/buttons";
@import "@/scss/forms";
@import "@/scss/tables";
@import "@/scss/flash";
@import "@/scss/animations";
@import "@/scss/transitions";
@import "@/scss/elements";
@import "@/scss/editor_content";
@import "@/scss/loading_bar";
@import "@/scss/popover";
@import "@/scss/sidebar";
@import "@/scss/datepicker";
@import "@/scss/grids";
@import "@/scss/icons";
@import "@/scss/resource_container";
@import "@/scss/header";

html {
  overflow: hidden;

  @media only screen and (max-width: $mobile-breakpoint) {
    overflow: scroll;
  }
}

* {
  box-sizing: border-box;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: $gray;
}

@media only screen and (width >= 2000px) {
  #app {
    max-width: 2000px;
    border-right: 1px solid $background-border-color;
  }
}

#wrapper {
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  justify-content: center;

  @media only screen and (min-width: $mobile-breakpoint) {
    min-height: 0;
  }
}

@media only screen and (min-width: $mobile-breakpoint) {
  .narrow {
    max-width: $narrow-view-width !important;
  }

  .narrow-center {
    max-width: $narrow-view-width !important;
    margin: 0 auto;
  }
}

#content {
  flex-grow: 1;
  overflow-y: scroll;

  @media only screen and (max-width: $mobile-breakpoint) {
    max-width: 100%;
    overflow: visible;
    background-color: $gray;
  }

  @media print {
    background-color: white;
  }
}

#main {
  max-width: $router-view-width;
  padding: $base-spacing $base-spacing * 2;
  margin: $base-spacing auto;
  background-color: $white;
  border: 1px solid $background-border-color;

  @media only screen and (max-width: $mobile-breakpoint) {
    padding: $base-spacing;
    margin-top: 0;
    border-top: 0;
  }

  @media print {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border: none;
  }

  &.full {
    padding: 0;
  }
}

.v-select:not(.vs--open) .vs__selected-options .vs__selected {
  z-index: 2;

  + .vs__search {
    position: absolute;
    width: 100%;
  }
}

.vs__dropdown-menu[data-popper-placement="top"] {
  border-top-style: solid;
  border-bottom-style: none;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}

.vs__dropdown-option--selected {
  font-weight: bold;
}

.powered-by {
  display: flex;
  justify-content: flex-end;
  padding: 0 $base-spacing;
  margin: 0 auto $base-spacing;
  text-align: right;
}
</style>
