import ResourceApi from "./resource_api"

import type Page from "@/models/Page"
type curResourceProperties = ResourcePropertiesOf<Page>

export default class PageApi extends ResourceApi<curResourceProperties> {
  namespace = "pages"
  type = "page"

  async publish(id: string): Promise<curResourceProperties> {
    const res = await this.axios.put(this.apiPath({ scope: `${id}/publish` }), {
      data: {
        id,
        type: this.type,
      },
    })

    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async hide(id: string): Promise<curResourceProperties> {
    const res = await this.axios.put(this.apiPath({ scope: `${id}/hide` }), {
      data: {
        id,
        type: this.type,
      },
    })

    return this.serializer.deserialize(res.data) as curResourceProperties
  }
}
