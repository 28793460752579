import Vue from "vue"

export function dig(obj: any, key: string | string[]) {
  if (!key) {
    return obj
  }

  const keys = Array.isArray(key) ? key : key.split(".")

  return keys.reduce((acc, cur) => {
    if (acc && Object.prototype.hasOwnProperty.call(acc, cur)) {
      return acc[cur]
    } else {
      return undefined
    }
  }, obj)
}

export function pick(obj: any, ...props: string[]) {
  return Object.assign({}, ...props.map((prop) => ({ [prop]: obj[prop] })))
}

export function setKey(obj: any, key: string | string[], value: any) {
  const keys = Array.isArray(key) ? key : key.split(".")

  keys.reduce((acc, cur, i, arr) => {
    if (i == arr.length - 1) {
      Vue.set(acc, cur, value)
    } else {
      if (!acc[cur]) {
        Vue.set(acc, cur, {})
      }

      return acc[cur]
    }
  }, obj)
}

export function deleteKey(obj: any, key: string | string[]) {
  if (!key) {
    return
  }

  const keys = Array.isArray(key) ? key : key.split(".")
  const last = dig(obj, keys.slice(0, -1).join("."))

  if (last) {
    Vue.delete(last, keys[keys.length - 1])
  }
}

export function flatten(
  obj: { [x: string]: unknown },
  parent: string | undefined = undefined,
  res: { [x: string]: string } = {},
) {
  Object.keys(obj).forEach((key) => {
    const propName = parent ? `${parent}.${key}` : key
    if (typeof obj[key] == "object") {
      flatten(obj[key] as Record<string, unknown>, propName, res)
    } else {
      res[propName] = obj[key] as string
    }
  })
  return res
}

export function decycle(obj: any, stack: any[] = []): any {
  if (!obj || typeof obj !== "object") return obj

  if (stack.includes(obj)) return null

  const s = stack.concat([obj])

  return Array.isArray(obj)
    ? obj.map((x) => decycle(x, s))
    : Object.fromEntries(
        Object.entries(obj).map(([k, v]) => [k, decycle(v, s)]),
      )
}

export function deleteBlankKeys(obj: any) {
  Object.keys(obj).forEach((key) => {
    const value = obj[key]
    if (
      (typeof value == "string" && !value) ||
      value === null ||
      value === undefined
    ) {
      delete obj[key]
    }
  })
}

export function deleteBlankKeysCopy(obj: any) {
  obj = { ...obj }
  deleteBlankKeys(obj)
  return obj
}

export function deleteAllKeys(obj: any) {
  Object.keys(obj).forEach((key) => {
    Vue.delete(obj, key)
  })
}

export function assign(target: any, values: any) {
  Object.keys(values).forEach((key) => {
    Vue.set(target, key, values[key])
  })
}

export function assignChanged(target: any, values: any) {
  Object.keys(values).forEach((key) => {
    const newValue = values[key]
    const existingValue = target[key]

    // this prevents that a trailing whitespace is deleted while editing
    // a record which is saved while it is edited
    if (typeof existingValue == "string" && typeof newValue == "string") {
      if (existingValue == newValue || existingValue.trim() == newValue) {
        return
      }
    }

    Vue.set(target, key, newValue)
  })
}

export function replaceWith(target: any, values: any) {
  deleteAllKeys(target)
  assign(target, values)
}
