import ApplicationResource from "@/models/ApplicationResource"

import Api from "@/api/sync_connection_api"

const API = new Api()

export default class SyncConnection extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare label?: string
}
