import ApplicationResource from "./ApplicationResource"

import Api from "@/api/custom_values_record_api"
const API = new Api()

export default class CustomValuesRecord extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare name?: string
  declare order?: number
}
