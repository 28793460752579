import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"

import Api from "@/api/academic_teaching_api"

const API = new Api()

export default class AcademicTeaching extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare as_guest?: boolean
  declare department?: string
  declare department_de?: string
  declare department_en?: string
  declare end_at?: string
  declare guest_host?: string
  declare note?: string
  declare sector?: string
  declare sector_de?: string
  declare sector_en?: string
  declare start_at?: string
  declare status?: string
  declare status_de?: string
  declare status_en?: string

  static readonly associations = {
    form: {
      type: "single",
    },
  }

  declare form?: Form
}
