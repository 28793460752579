import ResourceApi from "./resource_api"

import type MailMessage from "@/models/MailMessage"
type curResourceProperties = ResourcePropertiesOf<MailMessage>

export default class MailMessageApi extends ResourceApi<curResourceProperties> {
  namespace = "mail_messages"
  type = "mail_message"

  async preview(id: string): Promise<string> {
    const res = await this.axios.get(this.apiPath({ scope: `${id}/preview` }))

    return res.data.data.preview
  }

  async downloadAttachment(id: string, attachmentId: string) {
    window.open(
      this.apiUrl({
        scope: `${id}/attachment/${attachmentId}`,
      }),
    )
  }
}
