import ResourceApi from "./resource_api"

import type SmtpEmailAddress from "@/models/SmtpEmailAddress"

type curResourceProperties = ResourcePropertiesOf<SmtpEmailAddress>

export default class SmtpEmailAddressApi extends ResourceApi<curResourceProperties> {
  namespace = "smtp_email_addresses"
  type = "smtp_email_address"
}
