import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"

import Api from "@/api/contact_email_address_api"

const API = new Api()

export default class ContactEmailAddress extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare address?: string
  declare kind?: string
  declare kind_de?: string
  declare kind_en?: string
  declare primary_user_contact?: boolean
  declare user_login?: boolean
  declare verified_at?: string

  static readonly associations = {
    form: {
      type: "single",
    },
  }

  declare form?: Form

  displayLabel() {
    return this.address || this.id
  }
}
