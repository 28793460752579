import ApplicationResource from "./ApplicationResource"
import type Event from "./Event"

import Api from "@/api/text_content_api"

const API = new Api()

export default class TextContent extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare content_de?: string
  declare content_en?: string
  declare content_interpolated_de?: string
  declare content_interpolated_en?: string
  declare input_kind?: string
  declare kind?: string
  declare purpose?: string

  static readonly associations = {
    event: {
      type: "single",
    },
    resource: {
      type: "single",
    },
  }

  declare event?: Event

  fallbackLocale(): string {
    if (this.event) {
      return this.event.fallbackLocale()
    } else {
      return this._fallbackLocale()
    }
  }

  availableLocales(): string[] {
    if (this.event) {
      return this.event.availableLocales()
    } else {
      return this._availableLocales()
    }
  }
}
