import ApplicationResource from "./ApplicationResource"
import type Address from "@/models/Address"
import type Form from "@/models/Form"

import Api from "@/api/professional_career_api"

const API = new Api()

export default class ProfessionalCareer extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare company_name?: string
  declare end_at?: string
  declare founder?: boolean
  declare industry?: string
  declare industry_de?: string
  declare industry_en?: string
  declare job_title?: string
  declare position?: string
  declare start_at?: string
  declare status?: string
  declare status_de?: string
  declare status_en?: string
  declare topic?: string

  static readonly associations = {
    address: {
      type: "single",
    },
    form: {
      type: "single",
    },
  }

  declare address?: Address
  declare form?: Form
}
