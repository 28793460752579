import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"
import type SmtpConfig from "@/models/SmtpConfig"
import type Domain from "@/models/Domain"

import Api from "@/api/smtp_email_address_api"

const API = new Api()

export default class SmtpEmailAddress extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare address?: string
  declare for_all_domains?: boolean

  static readonly associations = {
    domains: {
      type: "many",
    },
    form: {
      type: "single",
    },
    smtp_config: {
      type: "single",
    },
  }

  declare domains: Domain[]
  declare form?: Form
  declare smtp_config?: SmtpConfig
}
