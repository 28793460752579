import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"
import type UniversityCourseOfStudy from "@/models/UniversityCourseOfStudy"
import type UniversityFaculty from "@/models/UniversityFaculty"
import type UniversityInstitute from "@/models/UniversityInstitute"

import Api from "@/api/university_api"

const API = new Api()

export default class University extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare meta: ApplicationResource["meta"] & {
    applied_permissions?: ResourceAppliedPermission[]
  }

  declare hidden?: boolean
  declare key?: string
  declare label_de?: string
  declare label_en?: string
  declare order?: number

  static readonly associations = {
    course_of_studies: {
      type: "many",
    },
    faculties: {
      type: "many",
    },
    form: {
      type: "single",
    },
    institutes: {
      type: "many",
    },
  }

  declare course_of_studies: UniversityCourseOfStudy[]
  declare faculties: UniversityFaculty[]
  declare form?: Form
  declare institutes: UniversityInstitute[]
}
