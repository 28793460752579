import StatisticsApi from "./statistics_api"

import type NewslettersStatistic from "@/models/NewslettersStatistic"

type curResourceProperties = ResourcePropertiesOf<NewslettersStatistic>

export default class NewslettersStatisticApi extends StatisticsApi<curResourceProperties> {
  namespace = "newsletters_statistics"
  type = "newsletters_statistic"
}
