import ApplicationResource from "./ApplicationResource"
import type NewsEntry from "@/models/NewsEntry"
import type Form from "@/models/Form"

import Api from "@/api/news_channel_api"

const API = new Api()

export default class NewsChannel extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare title_de?: string
  declare title_en?: string

  static readonly associations = {
    form: {
      type: "single",
    },
    news_entries: {
      type: "many",
    },
  }

  declare form?: Form
  declare news_entries: NewsEntry[]

  displayLabel() {
    return this.getLocalized("title") || this.id
  }
}
