import ApplicationResource from "./ApplicationResource"
import type EventApplication from "@/models/EventApplication"
import type EventAppointment from "@/models/EventAppointment"
import type EventCustomApplicationFormAssociation from "@/models/EventCustomApplicationFormAssociation"
import type EventSeries from "@/models/EventSeries"
import type Domain from "@/models/Domain"
import type Form from "@/models/Form"
import type InvoiceItem from "@/models/InvoiceItem"
import type MailTemplateUseCase from "@/models/MailTemplateUseCase"
import type PdfSetting from "@/models/PdfSetting"
import type PdfLayout from "@/models/PdfLayout"
import type Place from "@/models/Place"
import type Poll from "@/models/Poll"
import type Search from "@/models/Search"
import type SyncLogEntry from "@/models/SyncLogEntry"
import type SyncConnection from "@/models/SyncConnection"
import type Signature from "@/models/Signature"
import type Tag from "@/models/Tag"
import type TextContent from "@/models/TextContent"
import type User from "@/models/User"

import EventApi from "@/api/event_api"

type EventAppliedPermissions = ResourceAppliedPermission | "duplicate"

const API = new EventApi()

export default class Event extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  static readonly fileResources: string[] = ["logo", "attachments"]

  declare meta: ApplicationResource["meta"] & {
    application_not_possible_reasons?: string[]
    application_possible?: boolean
    applications?: {
      total: number
      at_least_handed_in_count: number
      by_state: { [x in EventApplicationState]: number }
      last_handed_in_at?: string
      free_places: number | null
    }
    applied_permissions?: EventAppliedPermissions[]
    application_status_color?: "red" | "yellow" | "green"
    assign_places?: boolean
    event_series?: {
      total: number
    }
    load_status_color?: "red" | "yellow" | "green" | "unknown"
    publicly_visible?: boolean
    invoice_pdf_layout_template_different?: boolean
  }

  declare application_end_at?: string
  declare application_possible_with_or_without_account?: string[]
  declare application_start_at?: string
  declare archived?: boolean
  declare archived_at?: string
  declare attachments?: FileResource[]
  declare canceled_at?: string
  declare cancellation_deadline?: string
  declare certificate_locales: string[]
  declare commission?: number
  declare contact_email?: string
  declare contact_first_name?: string
  declare contact_institute?: string
  declare contact_last_name?: string
  declare contact_phone?: string
  declare contact_title?: string
  declare contact_title_de?: string
  declare contact_title_en?: string
  declare description_de?: string
  declare description_en?: string
  declare email_token_required?: boolean
  declare end_at?: string
  declare evaluation_enabled?: boolean
  declare external_registration_url?: string
  declare hint?: string
  declare hint_de?: string
  declare hint_en?: string
  declare host?: string
  declare invoice_enabled?: boolean
  declare invoice_on_application?: boolean
  declare kind?: string[]
  declare kind_de?: string[]
  declare kind_en?: string[]
  declare language?: string
  declare locales?: string[]
  declare location?: string
  declare location_de?: string
  declare location_en?: string
  declare logo?: FileResource
  declare main_locale?: string
  declare note?: string
  declare num_active_applications?: number
  declare online_meeting_enabled?: boolean
  declare online_meeting_start_before?: number
  declare organizer?: string
  declare participants_maximum?: number
  declare participants_minimum?: number
  declare published_at?: string
  declare registration_process?: string
  declare single_appointments?: boolean
  declare slug?: string
  declare start_at?: string
  declare state?: EventState
  declare state_de?: string
  declare state_en?: string
  declare target_groups?: string[]
  declare target_groups_de?: string[]
  declare target_groups_en?: string[]
  declare teaser?: string
  declare teaser_de?: string
  declare teaser_en?: string
  declare title?: string
  declare title_de?: string
  declare title_en?: string
  declare visible_start_at?: string
  declare waiting_list_enabled?: boolean
  declare waiting_list_maximum?: number
  declare with_application_restriction?: boolean
  declare with_certificates?: boolean

  declare pastCancellationDeadline?: boolean
  declare upcomingCancellationDeadline?: boolean

  static readonly associations = {
    appointments: {
      type: "many",
      sort: (a: EventAppointment, b: EventAppointment) => {
        if (a.start_at && b.start_at) {
          return new Date(a.start_at).getTime() - new Date(b.start_at).getTime()
        }

        if (a.start_at) {
          return -1
        }

        if (b.start_at) {
          return 1
        }

        return (
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        )
      },
    },
    application_restriction: {
      type: "single",
    },
    archived_by: {
      type: "single",
    },
    canceled_by: {
      type: "single",
    },
    certificate_pdf_layout: {
      type: "single",
    },
    certificate_pdf_layout_template: {
      type: "single",
    },
    created_by: {
      type: "single",
    },
    custom_application_form_associations: {
      type: "many",
      sort: (
        a: EventCustomApplicationFormAssociation,
        b: EventCustomApplicationFormAssociation,
      ) => (a.order || 0) - (b.order || 0),
    },
    custom_application_forms: {
      type: "many",
    },
    custom_tags: {
      type: "many",
    },
    event_series: {
      type: "many",
    },
    domains: {
      type: "many",
    },
    duplicated_by: {
      type: "single",
    },
    invoice_items: {
      type: "many",
    },
    optional_invoice_items: {
      type: "many",
    },
    invoice_pdf_layout: {
      type: "single",
    },
    invoice_pdf_layout_template: {
      type: "single",
    },
    invoice_pdf_setting: {
      type: "single",
    },
    form: {
      type: "single",
    },
    mail_template_use_cases: {
      type: "many",
    },
    my_application: {
      type: "single",
    },
    places: {
      type: "many",
    },
    polls: {
      type: "many",
    },
    public_tags: {
      type: "many",
    },
    published_by: {
      type: "single",
    },
    signature: {
      type: "single",
    },
    sync_connections: {
      type: "many",
    },
    sync_log_entries: {
      type: "many",
    },
    system_tags: {
      type: "many",
    },
    tags: {
      type: "many",
    },
    text_contents: {
      type: "many",
    },
    uploads: {
      type: "many",
    },
  }

  declare application_restriction: Search
  declare appointments: EventAppointment[]
  declare archived_by?: User
  declare canceled_by?: User
  declare certificate_pdf_layout?: PdfLayout
  declare certificate_pdf_layout_template?: PdfLayout
  declare created_by?: User
  declare custom_application_form_associations: EventCustomApplicationFormAssociation[]
  declare custom_application_forms: Form[]
  declare custom_tags: Tag[]
  declare domains: Domain[]
  declare duplicated_by?: User
  declare event_series: EventSeries[]
  declare form?: Form
  declare invoice_items: InvoiceItem[]
  declare invoice_pdf_layout?: PdfLayout
  declare invoice_pdf_layout_synced_at?: string
  declare invoice_pdf_layout_template?: PdfLayout
  declare invoice_pdf_setting?: PdfSetting
  declare mail_template_use_cases: MailTemplateUseCase[]
  declare my_application?: EventApplication
  declare optional_invoice_items: InvoiceItem[]
  declare places: Place[]
  declare polls: Poll[]
  declare public_tags: Tag[]
  declare published_by?: User
  declare signature?: Signature
  declare sync_connections?: SyncConnection[]
  declare sync_log_entries?: SyncLogEntry[]
  declare system_tags: Tag[]
  declare tags: Tag[]
  declare text_contents: TextContent[]

  declare content_for_registration_added_cancel_button?: TextContent
  declare content_for_registration_added_hand_in_button?: TextContent
  declare content_for_registration_application_not_possible_text?: TextContent
  declare content_for_registration_application_not_within_restriction_text?: TextContent
  declare content_for_registration_application_period_ended_text?: TextContent
  declare content_for_registration_application_period_not_started_text?: TextContent
  declare content_for_registration_apply_again_button?: TextContent
  declare content_for_registration_cancel_button?: TextContent
  declare content_for_registration_canceled_text?: TextContent
  declare content_for_registration_decider_start_text?: TextContent
  declare content_for_registration_end_text?: TextContent
  declare content_for_registration_event_canceled_text?: TextContent
  declare content_for_registration_event_series_maximum_reached_text?: TextContent
  declare content_for_registration_external_text?: TextContent
  declare content_for_registration_form_text?: TextContent
  declare content_for_registration_hand_in_button?: TextContent
  declare content_for_registration_handed_in_accepted_text?: TextContent
  declare content_for_registration_handed_in_see_mail_text?: TextContent
  declare content_for_registration_handed_in_to_decide_text?: TextContent
  declare content_for_registration_handed_in_waiting_list_text?: TextContent
  declare content_for_registration_heading?: TextContent
  declare content_for_registration_no_more_places_text?: TextContent
  declare content_for_registration_select_appointment_text?: TextContent
  declare content_for_registration_select_appointment_title?: TextContent
  declare content_for_registration_start_text?: TextContent

  localInitialize() {
    this.upcomingCancellationDeadline = !!(
      this.cancellation_deadline &&
      new Date(this.cancellation_deadline) > new Date()
    )
    this.pastCancellationDeadline = !!(
      this.cancellation_deadline &&
      new Date(this.cancellation_deadline) < new Date()
    )

    this.text_contents.forEach((tc) => {
      ;(this as any)[`content_for_${tc.purpose}`] = tc
    })
  }

  fallbackLocale(): string {
    return this.main_locale || ""
  }

  availableLocales(): string[] {
    return this.locales || []
  }

  updateAssociationCallback({
    attr,
    action,
  }: {
    attr: string
    action: string
  }) {
    if (attr == "application_restriction") {
      this.with_application_restriction = action != "delete"
    }
  }

  displayLabel() {
    const title = this.getLocalized("title") || this.id

    if (!this.start_at) {
      return title
    }

    return `${title} (${this.$i18n.d(this.getDate("start_at"), "full")})`
  }
}
