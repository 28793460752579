import Vue from "vue"

import {
  FontAwesomeIcon,
  FontAwesomeLayersText,
  FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome"

Vue.component("FontAwesomeIcon", FontAwesomeIcon)
Vue.component("FontAwesomeLayers", FontAwesomeLayers)
Vue.component("FontAwesomeLayersText", FontAwesomeLayersText)
