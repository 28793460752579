import ApplicationResource from "./ApplicationResource"

import Api from "@/api/newsletter_schedule_api"

const API = new Api()

export default class NewsletterSchedule extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare send_at?: string
  declare send_now?: boolean
  declare sent_at?: string
}
