var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{key:_vm.$parent.key,attrs:{"id":"app"}},[_c('flash-message',{attrs:{"transition-name":"list"}}),(_vm.showTopBar)?_c('TopBar'):_vm._e(),_c('div',{attrs:{"id":"wrapper"}},[(_vm.sidebarAvailable)?_c(_vm.sidebar,{directives:[{name:"touch",rawName:"v-touch:swipe.left",value:(_vm.closeSidebar),expression:"closeSidebar",arg:"swipe",modifiers:{"left":true}}],ref:"sidebar",tag:"SideBar",attrs:{"id":"sidebar"}}):_vm._e(),_c('div',{attrs:{"id":"content"}},[_c('main',{class:_vm.mainClass,attrs:{"id":"main"},on:{"click":function($event){_vm.sidebarHidden = true}}},[_c('router-view',{ref:"view",on:{"site-title":_vm.setSiteTitle,"site-title-params":_vm.setSiteTitleParams}})],1),(_vm.showFooter)?_c('FooterComponent',{ref:"footer",class:_vm.mainClass,attrs:{"link-target":_vm.$route.meta.linksNewWindow ? '_blank' : null}}):_vm._e(),(_vm.showPoweredBy)?_c('aside',{staticClass:"powered-by",class:_vm.mainClass},[_c('div',{staticClass:"congeno"},[(_vm.$settings.congeno_logo)?_c('a',{attrs:{"href":_vm.$settings.congeno_logo_settings.link,"target":"_blank"}},[(_vm.$settings.congeno_logo)?_c('img',{staticClass:"congeno-logo",style:({
                height: _vm.$settings.congeno_logo_settings.height
                  ? _vm.$settings.congeno_logo_settings.height + 'px'
                  : false,
              }),attrs:{"src":_vm.$settings.congeno_logo.url}}):_vm._e()]):(_vm.$settings.congeno_mobile_logo)?_c('a',{attrs:{"href":_vm.$settings.congeno_logo_settings.link,"target":"_blank"}},[(_vm.$settings.congeno_mobile_logo)?_c('img',{staticClass:"congeno-logo",style:({
                height: _vm.$settings.congeno_mobile_logo_settings.height
                  ? _vm.$settings.congeno_mobile_logo_settings.height + 'px'
                  : false,
              }),attrs:{"src":_vm.$settings.congeno_mobile_logo.url}}):_vm._e()]):_vm._e()])]):_vm._e(),(_vm.modalOpen && _vm.modalName == 'MultipleAppointmentsModal')?_c('MultipleAppointmentsModal',{on:{"close":function($event){_vm.modalOpen = false}}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }