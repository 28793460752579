import { computed, onMounted } from "vue"

import { useStore } from "@/store"

import { type State as SettingsState } from "@/store/settings"

export const $settings = computed(() => {
  return useStore().state.settings
})

export const needsSettings = <T extends keyof SettingsState>(settings: T[]) => {
  onMounted(() => {
    useStore().dispatch("settings/fetch", settings)
  })

  return computed(() => useStore().state.settings as Pick<SettingsState, T>)
}

export default {
  install: (Vue: any) => {
    Object.defineProperty(Vue.prototype, "$settings", {
      get() {
        return $settings.value
      },
    })

    Vue.mixin({
      beforeCreate() {
        if (this.$options.settings) {
          useStore().dispatch("settings/fetch", this.$options.settings)
        }
      },
    })
  },
}
