import ApplicationResource from "@/models/ApplicationResource"
import type Event from "@/models/Event"
import type Form from "@/models/Form"

import EventAppointmentApi from "@/api/event_appointment_api"

const API = new EventAppointmentApi()

export default class EventAppointment extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare concurrent_places?: number
  declare end_at?: string
  declare minutes_pause_between_place?: number
  declare minutes_per_place?: number
  declare places?: number
  declare start_at?: string

  static readonly associations = {
    event: {
      type: "single",
    },
    form: {
      type: "single",
    },
  }

  declare event?: Event
  declare form?: Form
}
