import ApplicationResource from "./ApplicationResource"
import type Domain from "@/models/Domain"
import type Form from "@/models/Form"

import PlaceApi from "@/api/place_api"

const API = new PlaceApi()

export default class Place extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare label_de?: string
  declare label_en?: string
  declare name?: string

  static readonly associations = {
    domains: {
      type: "many",
    },
    form: {
      type: "single",
    },
  }

  declare domains: Domain[]
  declare form?: Form

  displayLabel() {
    return this.getLocalized("label") || this.name
  }
}
