import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"
import type Tag from "@/models/Tag"

import MailTemplateApi from "@/api/mail_templates_api"

const API = new MailTemplateApi()

type MailTemplateAppliedPermissions = ResourceAppliedPermission | "duplicate"

export default class MailTemplate extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare meta: ApplicationResource["meta"] & {
    applied_permissions?: MailTemplateAppliedPermissions[]
  }

  static readonly fileResources: string[] = ["attachments"]

  declare attachments?: FileResource[]
  declare body_de?: string
  declare body_en?: string
  declare for_all_domains?: boolean
  declare for_use_cases?: string[]
  declare for_use_cases_de?: string[]
  declare for_use_cases_en?: string[]
  declare global?: boolean
  declare internal_label?: string
  declare kind?: string
  declare locales?: string[]
  declare main_locale?: string
  declare subject_de?: string
  declare subject_en?: string

  static readonly associations = {
    form: {
      type: "single",
    },
    recipient_tags_to_add: {
      type: "many",
    },
  }

  declare form?: Form
  declare recipient_tags_to_add: Tag[]

  fallbackLocale(): string {
    return this.main_locale || ""
  }

  availableLocales(): string[] {
    return this.locales || []
  }
}
