import axios from "@/axios"
import serializer from "@/api/serializer"

import type StatisticResource from "@/models/StatisticResource"
type curResourceProperties = ResourcePropertiesOf<StatisticResource>

export type HistoryData = {
  label: string
  labels: Record<string, string>
  data: Array<{ t: string; tot: number; d: Array<{ k: string; v: number }> }>
}

export default abstract class StatisticsApi<T extends curResourceProperties> {
  namespace = ""
  type = ""
  serializer = serializer
  axios = axios

  apiPath({ scope, format }: { scope?: string; format?: string } = {}) {
    const base = scope ? `${this.namespace}/${scope}` : this.namespace

    if (!format) {
      return base
    }

    return `${base}.${format}`
  }

  async get({ filter }: { filter?: { [x: string]: unknown } } = {}): Promise<
    APIGetResponse<T>
  > {
    const res = await this.axios.get(this.apiPath(), {
      params: { filter },
    })

    return {
      data: this.serializer.deserialize(res.data) as T,
      meta: res.data.meta,
    }
  }

  async historyAvailable({ domain }: { domain?: string }): Promise<{
    data: Record<string, { label: string }>
  }> {
    const res = await this.axios.get(
      this.apiPath({ scope: "history_available" }),
      {
        params: {
          domain_id: domain,
        },
      },
    )

    return res.data
  }

  async history(
    graph: string,
    { filter }: { filter?: { [x: string]: unknown } } = {},
  ): Promise<{ data: HistoryData }> {
    const res = await this.axios.get(
      this.apiPath({ scope: `history/${graph}` }),
      {
        params: { filter },
      },
    )

    return res.data
  }
}
