export const deferred = function () {
  let res: (value: unknown) => unknown = () => {}
  let rej: (reason: unknown) => unknown = () => {}

  const promise = new Promise((resolve, reject) => {
    res = resolve
    rej = reject
  })

  return {
    promise,
    resolve: res,
    reject: rej,
  }
}

export const waitFor = async function (
  predicate: () => boolean,
  interval = 500,
  timeout = 30 * 1000,
) {
  const start = Date.now()

  // eslint-disable-next-line
  while (true) {
    if (predicate()) {
      break
    } else if (Date.now() > start + timeout) {
      throw new Error(
        `Timed out waiting for predicate to return true after ${timeout}ms.`,
      )
    }

    await new Promise((resolve) => setTimeout(resolve, interval))
  }
}

export const waitForShadow = async function () {
  await waitFor(() => {
    return document.querySelectorAll(".form-interpreter.processing").length == 0
  })
}
