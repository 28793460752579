import ResourceApi from "./resource_api"

import type Login from "@/models/Login"

type curResourceProperties = ResourcePropertiesOf<Login>

export default class LoginApi extends ResourceApi<curResourceProperties> {
  namespace = "logins"
  type = "login"

  async self() {
    const res = await this.axios.get(this.apiPath({ scope: "self" }), {
      params: { include: "user,user.tags,manage_domains,real_manage_domains" },
    })

    return {
      data: this.serializer.deserialize(res.data) as curResourceProperties,
      meta: res.data.meta,
    }
  }

  async signIn(login: curResourceProperties, opts: { otp?: string } = {}) {
    const res = await this.axios.post("auth/sign_in", {
      email: login.email,
      password: login.password,
      otp: opts.otp,
    })

    return {
      data: this.serializer.deserialize(res.data) as curResourceProperties,
      meta: res.data.meta,
    }
  }

  async signInByAccessToken(email: string, token: string) {
    const res = await this.axios.post("auth/sign_in_token", {
      email,
      token,
    })

    return {
      data: this.serializer.deserialize(res.data) as curResourceProperties,
      meta: res.data.meta,
    }
  }

  claim(login: curResourceProperties, { group }: { group?: string } = {}) {
    return this.axios.put(this.apiPath({ scope: "claim" }), {
      data: {
        attributes: {
          planned_email: login.planned_email,
          password: login.password,
          password_confirmation: login.password_confirmation,
          privacy_accepted: login.privacy_accepted,
          terms_accepted: login.terms_accepted,
          group,
        },
      },
    })
  }

  doClaim(id: string, token: string) {
    return this.axios.put(this.apiPath({ scope: `${id}/claim` }), {
      data: {
        attributes: {
          token,
        },
      },
    })
  }

  requestPasswordReset(email: string) {
    return this.axios.put(this.apiPath({ scope: "request_password_reset" }), {
      data: { email },
    })
  }

  checkResetPasswordToken(email: string, token: string) {
    return this.axios.put(
      this.apiPath({ scope: "check_reset_password_token" }),
      {
        data: { email, token },
      },
    )
  }

  resetPassword(
    email: string,
    token: string,
    password: string,
    passwordConfirmation: string,
  ) {
    return this.axios.put(this.apiPath({ scope: "reset_password" }), {
      data: {
        email,
        token,
        password,
        password_confirmation: passwordConfirmation,
      },
    })
  }

  requestAccessToken(email: string) {
    return this.axios.put(this.apiPath({ scope: "access_token" }), {
      data: { email },
    })
  }

  async createAccountUser({
    email,
    domain_id,
    firstName,
    lastName,
    withLogin,
  }: {
    email?: string
    domain_id?: string
    firstName?: string
    lastName?: string
    withLogin?: boolean
  }) {
    const resp = await this.axios.post(
      this.apiPath({ scope: "create_account_login" }),
      {
        domain_id,
        data: {
          first_name: firstName,
          last_name: lastName,
          email,
          with_login: withLogin,
        },
      },
    )

    return this.serializer.deserialize(resp.data) as curResourceProperties
  }

  createTeamUser({
    email,
    domain_id,
    firstName,
    lastName,
  }: {
    email: string
    domain_id?: string
    firstName?: string
    lastName?: string
  }) {
    return this.axios.post(this.apiPath({ scope: "create_team_login" }), {
      domain_id,
      data: { first_name: firstName, last_name: lastName, email },
    })
  }

  async makeTeamMember(
    id: string,
    opts: {
      globalRoleNames?: string[]
      regularAdminDomains?: string[]
      teamUserAdminDomains?: string[]
    } = {},
  ) {
    return this.axios.put(this.apiPath({ scope: `${id}/make_team_member` }), {
      data: {
        global_role_names: opts.globalRoleNames,
        regular_admin_domains: opts.regularAdminDomains,
        team_user_admin_domains: opts.teamUserAdminDomains,
      },
    })
  }
}
