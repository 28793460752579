import ResourceApi from "./resource_api"

import type Newsletter from "@/models/Newsletter"
import type NewsletterView from "@/models/NewsletterView"

type curResourceProperties = ResourcePropertiesOf<Newsletter>

export default class NewsletterApi extends ResourceApi<curResourceProperties> {
  namespace = "newsletters"
  type = "newsletter"

  async sendPreview(
    id: string,
    { locale }: { locale?: string } = {},
  ): Promise<void> {
    return this.axios.put(
      this.apiPath({ scope: `${id}/preview` }),
      {},
      {
        params: { locale },
      },
    )
  }

  async putIntoOutbox(id: string): Promise<void> {
    return this.axios.put(this.apiPath({ scope: `${id}/put_into_outbox` }))
  }

  async cancel(id: string): Promise<void> {
    return this.axios.put(this.apiPath({ scope: `${id}/cancel` }))
  }

  async preview(
    id: string,
    { locale }: { locale?: string } = {},
  ): Promise<ResourcePropertiesOf<NewsletterView>> {
    const resp = await this.axios.get(
      this.apiPath({ scope: `${id}/preview` }),
      {
        params: { locale },
      },
    )

    return this.serializer.deserialize(
      resp.data,
    ) as ResourcePropertiesOf<NewsletterView>
  }

  async view(
    token: string,
    { locale }: { locale?: string } = {},
  ): Promise<ResourcePropertiesOf<NewsletterView>> {
    const resp = await this.axios.get(this.apiPath({ scope: "view" }), {
      params: { token, view_locale: locale },
    })

    return this.serializer.deserialize(
      resp.data,
    ) as ResourcePropertiesOf<NewsletterView>
  }
}
