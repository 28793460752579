import ApplicationResource from "@/models/ApplicationResource"
import type Domain from "@/models/Domain"
import type Event from "@/models/Event"
import type EventApplication from "@/models/EventApplication"
import type Form from "@/models/Form"
import type InvoiceItem from "@/models/InvoiceItem"
import type PdfLayout from "@/models/PdfLayout"
import type PdfSetting from "@/models/PdfSetting"
import type User from "@/models/User"

import Api from "@/api/invoice_api"

const API = new Api()

export default class Invoice extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  static readonly fileResources: string[] = ["pdf"]

  declare meta: ApplicationResource["meta"] & {
    applied_permissions?: ResourceAppliedPermission[]
    default_pdf_layout_template_different?: boolean
  }

  declare address?: string
  declare city?: string
  declare date?: string
  declare due_date?: string
  declare locale?: string
  declare number?: string
  declare pdf?: FileResource
  declare state?: string
  declare state_de?: string
  declare state_en?: string
  declare total_sum?: number

  static readonly associations = {
    domains: {
      type: "many",
    },
    form: {
      type: "single",
    },
    invoice_items: {
      type: "multiple",
      sort: (a: InvoiceItem, b: InvoiceItem) => (a.order || 0) - (b.order || 0),
    },
    default_pdf_layout: {
      type: "single",
    },
    default_pdf_layout_template: {
      type: "single",
    },
    pdf_setting: {
      type: "single",
    },
    user: {
      type: "single",
    },
    events: {
      type: "multiple",
    },
    event_applications: {
      type: "multiple",
    },
  }

  declare default_pdf_layout?: PdfLayout
  declare default_pdf_layout_template?: PdfLayout
  declare domains: Domain[]
  declare event_applications: EventApplication[]
  declare events: Event[]
  declare form?: Form
  declare invoice_items: InvoiceItem[]
  declare pdf_setting?: PdfSetting
  declare user?: User

  fallbackLocale(): string {
    return this.locale || this._fallbackLocale()
  }

  availableLocales(): string[] {
    return this.locale ? [this.locale] : this._availableLocales()
  }
}
