import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"

import Api from "@/api/smtp_config_api"

const API = new Api()

export default class SmtpConfig extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare meta: ApplicationResource["meta"] & {
    completed?: boolean
  }

  declare active?: boolean
  declare address?: string
  declare from?: string
  declare from_regex?: string
  declare port?: number
  declare ssl?: boolean
  declare username?: string

  static readonly associations = {
    form: {
      type: "single",
    },
  }

  declare form?: Form
}
