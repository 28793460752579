import ResourceApi from "./resource_api"

import type EventCustomApplicationFormAssociation from "@/models/EventCustomApplicationFormAssociation"
type curResourceProperties =
  ResourcePropertiesOf<EventCustomApplicationFormAssociation>

export default class EventCustomApplicationFormAssociationApi extends ResourceApi<curResourceProperties> {
  namespace = "event_custom_application_form_associations"
  type = "event_custom_application_form_association"
}
