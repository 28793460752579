import ResourceApi from "./resource_api"

import type NewsEntry from "@/models/NewsEntry"
type curResourceProperties = ResourcePropertiesOf<NewsEntry>

export default class NewsEntryApi extends ResourceApi<curResourceProperties> {
  namespace = "news_entries"
  type = "news_entry"

  async publish(id: string): Promise<curResourceProperties> {
    const res = await this.axios.put(this.apiPath({ scope: `${id}/publish` }), {
      id,
      type: "NewsEntry",
    })

    return this.serializer.deserialize(res.data) as curResourceProperties
  }
}
