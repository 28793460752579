import Vue from "vue"

export interface SortOptionCore {
  i18nKey: string
  value: SortOption["value"]
}

export default Vue.extend({
  computed: {
    sortOptionsCoreWithLabel(): SortOption[] {
      const sortOptionsCore: SortOptionCore[] = (this as any).sortOptionsCore
      if (!sortOptionsCore) {
        return []
      }

      return this.sortOptionsCoreToSortOptions(sortOptionsCore)
    },
  },
  methods: {
    sortOptionsCoreToSortOptions(cores: SortOptionCore[]): SortOption[] {
      return cores.map((soc) => {
        return { label: this.$curT(soc.i18nKey) as string, value: soc.value }
      })
    },
  },
})
