import ApplicationResource from "./ApplicationResource"
import EventApplicationApi from "@/api/event_application_api"

import type Address from "@/models/Address"
import type Event from "@/models/Event"
import type EventAppointment from "@/models/EventAppointment"
import type Form from "@/models/Form"
import type Invoice from "@/models/Invoice"
import type Poll from "@/models/Poll"
import type Tag from "@/models/Tag"
import type User from "@/models/User"

const API = new EventApplicationApi()

export default class EventApplication extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  static readonly fileResources: string[] = ["certificate"]

  declare meta: ApplicationResource["meta"] & {
    applied_permissions?: ResourceAppliedPermission[] & ["join_meeting"]
    possible_events?: EventApplicationEvents[]
    certificate_available?: boolean
    invoice_available?: boolean
    join_meeting_at?: string
  }

  declare application_started_at?: string
  declare canceled_at?: string
  declare certificate?: FileResource
  declare end_at?: string
  declare event_id?: string
  declare handed_in_at?: string
  declare participation?: string
  declare participation_de?: string
  declare participation_en?: string
  declare scrubbed?: boolean
  declare scrubbed_at?: string
  declare single_appointment_at?: string
  declare start_at?: string
  declare state?: EventApplicationState
  declare state_de?: string
  declare state_en?: string
  declare waiting_list_position?: number

  static readonly associations = {
    custom_application_forms: {
      type: "many",
    },
    custom_tags: {
      type: "many",
    },
    event: {
      type: "single",
    },
    event_appointment: {
      type: "single",
    },
    form: {
      type: "single",
    },
    moved_from_event_application: {
      type: "single",
    },
    moved_to_event_application: {
      type: "single",
    },
    poll: {
      type: "single",
    },
    invoice: {
      type: "single",
    },
    invoice_address: {
      type: "single",
    },
    system_tags: {
      type: "many",
    },
    tags: {
      type: "many",
    },
    user: {
      type: "single",
    },
  }

  declare custom_application_forms: Form[]
  declare custom_tags: Tag[]
  declare event?: Event
  declare event_appointment?: EventAppointment
  declare form?: Form
  declare invoice?: Invoice
  declare invoice_address?: Address
  declare moved_from_event_application?: EventApplication
  declare moved_to_event_application?: EventApplication
  declare poll?: Poll
  declare system_tags: Tag[]
  declare tags: Tag[]
  declare user?: User

  possibleEvents(): Set<EventApplicationEvents> {
    return new Set(this.meta?.possible_events || [])
  }

  joinMeetingTimeReached() {
    return (
      this.meta.join_meeting_at &&
      new Date(this.meta.join_meeting_at) <= new Date()
    )
  }
}
