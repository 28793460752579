import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"

import PollApi from "@/api/poll_api"

type PollAppliedPermissions =
  | ResourceAppliedPermission
  | "duplicate"
  | "publish"
  | "archive"

const API = new PollApi()

export default class Poll extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare meta: ApplicationResource["meta"] & {
    applied_permissions?: PollAppliedPermissions[]
    poll_votes: {
      total: number
    }
    poll_vote_statistics?: Array<{
      labels: string[]
      backgroundColor?: string[]
      label?: string
      hint?: string
      datasets: Array<{
        data?: number[]
        values?: Record<string, string | string[]>
      }>
    }>
  }

  declare archived?: boolean
  declare archived_at?: string
  declare description_de?: string
  declare description_en?: string
  declare hidden_in_lists?: boolean
  declare locales?: string[]
  declare main_locale?: string
  declare published_at?: string
  declare state?: string
  declare state_de?: string
  declare state_en?: string
  declare title?: string
  declare title_de?: string
  declare title_en?: string
  declare visible_end_at?: string
  declare visible_start_at?: string
  declare vote_period_end_at?: string
  declare vote_period_start_at?: string
  declare vote_without_account?: boolean

  static readonly associations = {
    contact_details_form: {
      type: "single",
    },
    custom_form: {
      type: "single",
    },
    user_form: {
      type: "single",
    },
    form: {
      type: "single",
    },
    resource: {
      type: "single",
    },
  }

  declare contact_details_form?: Form
  declare custom_form?: Form
  declare form?: Form
  declare resource?: ApplicationResource
  declare user_form?: Form

  fallbackLocale(): string {
    return this.main_locale || ""
  }

  availableLocales(): string[] {
    return this.locales || []
  }
}
