import ApplicationResource from "./ApplicationResource"
import type MailTemplate from "@/models/MailTemplate"
import MailTemplateUseCaseApi from "@/api/mail_template_use_case_api"

export const API = new MailTemplateUseCaseApi()

export default class MailTemplateUseCase extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare mail_template_id?: string
  declare resource_id?: string
  declare resource_type?: string
  declare use_case?: string
  declare use_case_de?: string
  declare use_case_en?: string

  static readonly associations = {
    mail_template: {
      type: "single",
    },
    resource: {
      type: "single",
    },
  }

  declare mail_template?: MailTemplate
  declare resource?: ApplicationResource
}
