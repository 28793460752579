import ApplicationResource from "@/models/ApplicationResource"
import type Form from "@/models/Form"
import type PdfSetting from "@/models/PdfSetting"

import Api from "@/api/pdf_setting_text_api"

const API = new Api()

export default class PdfSettingText extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare position_x?: number
  declare position_y?: number
  declare text_de?: string
  declare text_en?: string
  declare width?: number

  static readonly associations = {
    form: {
      type: "single",
    },
    pdf_setting: {
      type: "single",
    },
  }

  declare form?: Form
  declare pdf_setting?: PdfSetting
}
