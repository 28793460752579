import ResourceApi from "./resource_api"

import type MainCategory from "@/models/MainCategory"

type curResourceProperties = ResourcePropertiesOf<MainCategory>

export default class MainCategoryApi extends ResourceApi<curResourceProperties> {
  namespace = "main_categories"
  type = "main_category"
}
