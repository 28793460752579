import ApplicationResource from "./ApplicationResource"
import type Search from "@/models/Search"

import Api from "@/api/search_filter_api"

const API = new Api()

export default class SearchFilter extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare name?: string
  declare value?: unknown

  static readonly associations = {
    search: {
      type: "single",
    },
  }

  declare search?: Search
}
