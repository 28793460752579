import StatisticsApi from "./statistics_api"

import type UsersStatistic from "@/models/UsersStatistic"

type curResourceProperties = ResourcePropertiesOf<UsersStatistic>

export default class UsersStatisticApi extends StatisticsApi<curResourceProperties> {
  namespace = "users_statistics"
  type = "users_statistic"
}
