import ResourceApi from "./resource_api"

import type Domain from "@/models/Domain"

type curResourceProperties = ResourcePropertiesOf<Domain>

export default class DomainApi extends ResourceApi<curResourceProperties> {
  namespace = "domains"
  type = "domain"

  async addRegularAdmin(id: string, loginId: string) {
    return this.axios.put(this.apiPath({ scope: `${id}/add_regular_admin` }), {
      data: { login_id: loginId },
    })
  }

  async removeRegularAdmin(id: string, loginId: string) {
    return this.axios.delete(
      this.apiPath({ scope: `${id}/remove_regular_admin` }),
      {
        data: { data: { login_id: loginId } },
      },
    )
  }
}
