import Vue from "vue"

export default Vue.extend({
  computed: {
    siteTitle(): string | null | undefined {
      return undefined
    },
    siteTitleParams(): Record<string, string> | undefined {
      return undefined
    },
  },
  watch: {
    siteTitle(val: string | null | undefined) {
      this.$emit("site-title", val)
    },
    siteTitleParams: {
      handler(val: unknown) {
        this.$emit("site-title-params", val)
      },
      deep: true,
    },
  },
  created() {
    this.$emit("site-title", undefined)
    this.$emit("site-title-params", undefined)
  },
})
