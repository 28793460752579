import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"
import type User from "@/models/User"
import type Poll from "@/models/Poll"

import PollVoteApi from "@/api/poll_vote_api"

type PollVoteAppliedPermissions = ResourceAppliedPermission | "scrub"

const API = new PollVoteApi()

export default class PollVote extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare meta: ApplicationResource["meta"] & {
    applied_permissions?: PollVoteAppliedPermissions[]
  }

  declare voted_at?: string
  declare verified_at?: string
  declare scrubbed_at?: string
  declare scrubbed?: boolean

  static readonly associations = {
    user_form: {
      type: "single",
    },
    default_form: {
      type: "single",
    },
    user: {
      type: "single",
    },
    poll: {
      type: "single",
    },
  }

  declare default_form?: Form
  declare poll?: Poll
  declare user?: User
  declare user_form?: Form
}
