import Vue from "vue"
import { MutationTree, ActionTree, Module } from "vuex"

import { deleteAllKeys } from "@/utils/object_utils"

import { RootState } from "@/store/config"

import FormInputOption from "@/models/FormInputOption"

export interface State {
  options: {
    [x: string]: FormInputOption[]
  }
}

export const STATE: State = {
  options: {},
}

export const MUTATIONS: MutationTree<State> = {
  clear(state: State) {
    deleteAllKeys(state.options)
  },
  setOptions(
    state: State,
    { id, options }: { id: string; options: FormInputOption[] },
  ) {
    if (state.options[id]) {
      state.options[id].splice(0)
      state.options[id].push(...options)
    } else {
      Vue.set(state.options, id, options)
    }
  },
}

export const ACTIONS: ActionTree<State, RootState> = {
  async fetchOptions({ commit, state }, id: string) {
    if (!state.options[id]) {
      commit("setOptions", {
        id,
        options: [],
      })

      const resp = await FormInputOption.api.all({
        collectionParams: {
          scopeFilter: { form_input_id: id },
          sort: ["order"],
        },
      })

      commit("setOptions", {
        id,
        options: resp.data.map((option) => new FormInputOption(option)),
      })
    }

    return state.options[id]
  },
}

const FormInputOptions: Module<State, RootState> = {
  namespaced: true,
  state: STATE,
  mutations: MUTATIONS,
  actions: ACTIONS,
}

export default FormInputOptions
