import Vue from "vue"
import { loadLocaleAsync } from "@/i18n"

export default Vue.extend({
  computed: {
    $curTScope(): string {
      return this.$options.name || ""
    },
  },
  watch: {
    "$i18n.locale"() {
      this.reloadI18nData()
    },
  },
  created() {
    this.reloadI18nData()
  },
  methods: {
    $curT(key: string, ...rest: any[]) {
      return this.$t(this.$curTPath(key), ...rest)
    },
    $curTc(key: string, ...rest: any[]) {
      return this.$tc(this.$curTPath(key), ...rest)
    },
    $curTe(key: string, ...rest: any[]) {
      return this.$te(this.$curTPath(key), ...rest)
    },
    $curTPath(key: string) {
      return `${this.$curTScope}.${key}`
    },
    reloadI18nData() {
      if (!this.$curTScope) {
        return
      }

      return loadLocaleAsync(this.$i18n.locale, this.$curTScope)
    },
  },
})
