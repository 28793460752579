import Vue from "vue"

let mdParser: any = null

Vue.directive("markdown", async (el, binding) => {
  let curMdParser = mdParser

  if (!curMdParser) {
    const MD = (await import("markdown-it")).default
    curMdParser = MD("commonmark", { html: false })
    mdParser = curMdParser
  }

  el.innerHTML = curMdParser.render(binding.value)
})
