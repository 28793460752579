import ApplicationResource from "./ApplicationResource"
import type Event from "@/models/Event"
import type Form from "@/models/Form"

import Api from "@/api/event_custom_application_form_association_api"

const API = new Api()

export default class EventCustomApplicationFormAssociation extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare order?: number

  static readonly associations = {
    event: {
      type: "single",
    },
    form: {
      type: "single",
    },
  }

  declare form?: Form
  declare event?: Event
}
