export function deleteIf<T>(arr: T[], cb: (elem: T, i: number) => boolean) {
  let i = 0
  while (i < arr.length) {
    if (cb(arr[i], i)) {
      arr.splice(i, 1)
    } else {
      ++i
    }
  }
}
