import ResourceApi from "./resource_api"

import type JobLogEntry from "@/models/JobLogEntry"
type curResourceProperties = ResourcePropertiesOf<JobLogEntry>

export default class JobLogEntryApi extends ResourceApi<curResourceProperties> {
  namespace = "job_log_entries"
  type = "job_log_entry"

  downloadResultFilePath(id: string): string {
    return this.apiUrl({
      scope: `${id}/download_result_file`,
      format: "xlsx",
    })
  }
}
