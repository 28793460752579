import ResourceApi from "./resource_api"

import type Invoice from "@/models/Invoice"
type curResourceProperties = ResourcePropertiesOf<Invoice>

export default class InvoiceApi extends ResourceApi<curResourceProperties> {
  namespace = "invoices"
  type = "invoice"

  downloadPath(number: string) {
    return this.apiUrl({
      scope: `download/${number}`,
      format: "pdf",
    })
  }

  downloadZipPath({
    collectionParams,
    query,
  }: {
    collectionParams?: APIResourceCollectionParams
    query?: {}
  } = {}) {
    const params = (query && { ...query }) || {}
    if (collectionParams) {
      this.addCollectionParamsToObject(collectionParams, params)
    }

    return this.apiUrl({
      scope: `download_zip/invoices.zip`,
      params,
    })
  }

  async resetDefaultPdfLayout(id: string) {
    await this.axios.put(
      this.apiPath({ scope: `${id}/reset_default_pdf_layout` }),
      {
        id,
        type: this.type,
      },
    )

    return true
  }
}
