import ApplicationResource from "@/models/ApplicationResource"
import type Form from "@/models/Form"
import type PdfSettingText from "@/models/PdfSettingText"

import Api from "@/api/pdf_setting_api"

const API = new Api()

export default class PdfSetting extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  static readonly fileResources: string[] = [
    "template_de",
    "template_en",
    "font_normal",
    "font_bold",
    "font_italic",
  ]

  declare font_bold?: FileResource
  declare font_italic?: FileResource
  declare font_leading?: number
  declare font_normal?: FileResource
  declare font_size?: number
  declare purpose?: string
  declare purpose_de?: string
  declare purpose_en?: string
  declare template?: FileResource

  static readonly associations = {
    form: {
      type: "single",
    },
    pdf_setting_texts: {
      type: "name",
    },
  }

  declare form?: Form
  declare pdf_setting_texts: PdfSettingText[]
}
