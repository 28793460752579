import ResourceApi from "@/api/resource_api"

import type SyncLogEntry from "@/models/SyncLogEntry"

type curResourceProperties = ResourcePropertiesOf<SyncLogEntry>

export default class SyncLogEntryApi extends ResourceApi<curResourceProperties> {
  namespace = "sync_log_entries"
  type = "sync_log_entry"
}
