import ApplicationResource from "@/models/ApplicationResource"
import type Form from "@/models/Form"
import type FormInputOption from "@/models/FormInputOption"
import type Invoice from "@/models/Invoice"

import Api from "@/api/invoice_item_api"

const API = new Api()

export default class InvoiceItem extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare label_de?: string
  declare label_en?: string
  declare number?: string
  declare order?: number
  declare price?: string

  static readonly associations = {
    form: {
      type: "single",
    },
    form_input_options: {
      type: "many",
    },
    invoice: {
      type: "single",
    },
  }

  declare form?: Form
  declare form_input_options: FormInputOption[]
  declare invoice?: Invoice

  fallbackLocale(): string {
    return this.invoice?.fallbackLocale() || this._fallbackLocale()
  }

  availableLocales(): string[] {
    return this.invoice?.availableLocales() || this._availableLocales()
  }
}
