import ApplicationResource from "@/models/ApplicationResource"
import type Event from "@/models/Event"
import type Form from "@/models/Form"
import type FormInput from "@/models/FormInput"
import type InvoiceItem from "@/models/InvoiceItem"

import FormInputOptionApi from "@/api/form_input_option_api"

const API = new FormInputOptionApi()

export default class FormInputOption extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare meta: ApplicationResource["meta"] & {
    limit_reached?: boolean
    applications_with_option?: number
  }

  declare disabled?: boolean
  declare label?: string
  declare label_de?: string
  declare label_en?: string
  declare limit?: number
  declare main_point?: boolean
  declare order?: number
  declare place?: string
  declare place_de?: string
  declare place_en?: string
  declare price_adjustment?: number
  declare value?: string
  declare virtual?: boolean

  static readonly associations = {
    event: {
      type: "single",
    },
    form: {
      type: "single",
    },
    invoice_items: {
      type: "many",
    },
    form_input: {
      type: "single",
    },
  }

  declare event?: Event
  declare form?: Form
  declare form_input?: FormInput
  declare invoice_items: InvoiceItem[]

  labelWithInfo(
    locale: string,
    opts: {
      withPrice?: boolean
      withLimit?: boolean
      withPlacesTaken?: boolean
    } = {},
  ) {
    opts.withLimit ??= true
    opts.withPrice ??= true
    opts.withPlacesTaken ??= true

    let label = this.getLocalized("label", locale)

    if (
      opts.withPrice &&
      this.price_adjustment &&
      (!this.event || this.event.invoice_enabled)
    ) {
      const formatted = Intl.NumberFormat(locale, {
        style: "currency",
        currency: "EUR",
      }).format(this.price_adjustment / 100)

      if (this.price_adjustment > 0) {
        label = `${label} (+${formatted})`
      } else {
        label = `${label} (${formatted})`
      }
    }

    if (opts.withLimit && this.meta.limit_reached) {
      const translation =
        locale == "en" ? "fully booked already" : "bereits ausgebucht"
      label = `${label} **${translation}**`
    }

    if (
      opts.withPlacesTaken &&
      this.meta.applications_with_option !== undefined
    ) {
      label = `${label} (${this.meta.applications_with_option}`
      if (this.limit) {
        label = `${label}/${this.limit}`
      }
      label = `${label})`
    }

    return label
  }
}
