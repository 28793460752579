import ApplicationResource from "./ApplicationResource"
import type User from "./User"

import MailMessageApi from "@/api/mail_message_api"

const API = new MailMessageApi()

export default class MailMessage extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare meta: ApplicationResource["meta"] & {
    num_attachments?: number
  }

  declare body_text?: string
  declare confidential?: boolean
  declare failed_at?: string
  declare from?: string
  declare preview?: string
  declare scrubbed_at?: string
  declare sent_at?: string
  declare status?: string
  declare status_de?: string
  declare status_en?: string
  declare subject?: string
  declare to?: string

  static readonly associations = {
    user: {
      type: "single",
    },
  }

  declare user?: User
}
