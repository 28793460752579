import ApplicationResource from "./ApplicationResource"
import type Login from "./Login"

import Api from "@/api/log_entry_api"

const API = new Api()

export default class LogEntry extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare event?: string
  declare event_de?: string
  declare event_en?: string
  declare meta_data?: Record<string, unknown>
  declare resource_id?: string
  declare resource_type?: string

  static readonly associations = {
    login: {
      type: "single",
    },
    resource: {
      type: "single",
    },
  }

  declare login?: Login
  declare resource?: ApplicationResource
}
