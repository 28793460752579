import Jsona from "jsona"
import { AxiosResponse } from "axios"
import { TJsonApiData } from "jsona/lib/JsonaTypes"

const serializer = new Jsona()

export default {
  deserialize(data: object | AxiosResponse) {
    return serializer.deserialize(data)
  },
  serialize(data: TJsonApiData) {
    return serializer.serialize({ stuff: data })
  },
}
