import ApplicationResource from "./ApplicationResource"
import type Event from "@/models/Event"
import type Form from "@/models/Form"

import EventSeriesApi from "@/api/event_series_api"

const API = new EventSeriesApi()

export default class EventSeries extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare meta: ApplicationResource["meta"] & {
    applied_permissions?: ResourceAppliedPermission[]
    events?: {
      total: number
      draft: number
      published: number
      canceled: number
    }
  }

  declare description?: string
  declare description_de?: string
  declare description_en?: string
  declare end_at?: string
  declare hint?: string
  declare hint_de?: string
  declare hint_en?: string
  declare kind?: string[]
  declare kind_de?: string[]
  declare kind_en?: string[]
  declare locales?: string[]
  declare main_locale?: string
  declare start_at?: string
  declare state?: string
  declare state_de?: string
  declare state_en?: string
  declare teaser?: string
  declare teaser_de?: string
  declare teaser_en?: string
  declare title?: string
  declare title_de?: string
  declare title_en?: string
  declare visible_start_at?: string

  static readonly associations = {
    events: {
      type: "many",
      sort: (a: Event, b: Event) => {
        if (a.start_at && b.start_at) {
          return new Date(a.start_at).getTime() - new Date(b.start_at).getTime()
        }

        if (a.start_at) {
          return -1
        }

        if (b.start_at) {
          return 1
        }

        return 0
      },
    },
    form: {
      type: "single",
    },
  }

  declare events: Event[]
  declare form?: Form

  fallbackLocale(): string {
    return this.main_locale || "de"
  }

  availableLocales(): string[] {
    return this.locales || []
  }

  displayLabel() {
    return this.getLocalized("title") || this.id
  }
}
