import ResourceApi from "./resource_api"

import type Form from "@/models/Form"
type curResourceProperties = ResourcePropertiesOf<Form>

export default class FormApi extends ResourceApi<curResourceProperties> {
  namespace = "forms"
  type = "form"

  async getByName(
    name: string,
  ): Promise<APIGetResponse<curResourceProperties>> {
    const res = await this.axios.get(
      this.apiPath({ scope: `by_name/${name}` }),
      {
        params: {
          include:
            "form_inputs.real_form_input_options,sub_forms.form_inputs.real_form_input_options,sub_forms.sub_forms.form_inputs.real_form_input_options,sub_forms.sub_forms.sub_forms.form_inputs.real_form_input_options,sub_forms.sub_forms.sub_forms.sub_forms.form_inputs.real_form_input_options",
        },
      },
    )

    return {
      data: this.serializer.deserialize(res.data) as curResourceProperties,
      meta: res.data.meta,
    }
  }

  async createForPurpose(purpose: string, opts: { domainId?: string } = {}) {
    const res = await this.axios.post(
      this.apiPath({ scope: "create_for_purpose" }),
      {
        data: { purpose, domain_id: opts.domainId },
      },
    )

    return this.serializer.deserialize(res.data) as curResourceProperties
  }
}
