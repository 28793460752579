type curResourceProperties = ResourcePropertiesOf<StatisticResource>

export default class StatisticResource implements ResourceIdentifier {
  declare ["constructor"]: typeof StatisticResource

  readonly id: string
  readonly type: string

  readonly isStatisticResource: boolean = true

  constructor(data: ResourcePropertiesOf<StatisticResource>) {
    this.id = data.id
    this.type = data.type

    this.initialize({ ...data })
  }

  initialize(data: Partial<curResourceProperties>) {
    Object.assign(this, data)
  }
}
