import Vue from "vue"

import Vuex, { Store } from "vuex"
import storeConfig, { RootState } from "@/store/config"

Vue.use(Vuex)

let store: Store<RootState> | undefined = undefined

export const useStore = () => {
  if (!store) {
    store = new Vuex.Store(storeConfig)
  }

  return store
}

export default useStore
