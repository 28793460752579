import ApplicationResource from "@/models/ApplicationResource"
import type SyncConnection from "@/models/SyncConnection"
import type User from "@/models/User"

import Api from "@/api/sync_log_entry_api"

const API = new Api()

export default class SyncLogEntry extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare action?: string
  declare action_de?: string
  declare action_en?: string
  declare direction?: string
  declare direction_de?: string
  declare direction_en?: string
  declare finished_at?: string
  declare namespace?: string
  declare status?: string
  declare status_de?: string
  declare status_en?: string

  static readonly associations = {
    sync_connection: {
      type: "single",
    },
    triggered_by: {
      type: "single",
    },
  }

  declare sync_connection?: SyncConnection
  declare triggered_by?: User
}
