import ApplicationResource from "@/models/ApplicationResource"
import type Form from "@/models/Form"

import Api from "@/api/social_media_link_api"

const API = new Api()

export default class SocialMediaLink extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare kind?: string
  declare kind_de?: string
  declare kind_en?: string
  declare url?: string

  static readonly associations = {
    form: {
      type: "single",
    },
  }

  declare form?: Form
}
