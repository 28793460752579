import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"
import type MainCategory from "@/models/MainCategory"
import type University from "@/models/University"
import type UniversityCourseOfStudy from "@/models/UniversityCourseOfStudy"
import type UniversityFaculty from "@/models/UniversityFaculty"
import type UniversityInstitute from "@/models/UniversityInstitute"
import type User from "@/models/User"

import Api from "@/api/academic_career_api"

const API = new Api()

export default class AcademicCareer extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare category?: string
  declare category_de?: string
  declare category_en?: string
  declare degree?: string
  declare degree_de?: string
  declare degree_en?: string
  declare end_at?: string
  declare matriculation_number?: string
  declare note?: string
  declare num_semesters?: number
  declare start_at?: string

  static readonly associations = {
    course_of_study: {
      type: "single",
    },
    course_of_study_main_categories: {
      type: "many",
    },
    faculty: {
      type: "single",
    },
    form: {
      type: "single",
    },
    institute: {
      type: "single",
    },
    main_categories: {
      type: "many",
    },
    university: {
      type: "single",
    },
    user: {
      type: "single",
    },
  }

  declare course_of_study?: UniversityCourseOfStudy
  declare course_of_study_main_categories: MainCategory[]
  declare faculty?: UniversityFaculty
  declare form?: Form
  declare institute?: UniversityInstitute
  declare main_categories: MainCategory[]
  declare university?: University
  declare user?: User
}
