import ResourceApi from "@/api/resource_api"

import type SyncConnection from "@/models/SyncConnection"

type curResourceProperties = ResourcePropertiesOf<SyncConnection>

export default class SyncConnectionApi extends ResourceApi<curResourceProperties> {
  namespace = "sync_connections"
  type = "sync_connection"
}
