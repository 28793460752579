import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"

import Api from "@/api/address_api"

const API = new Api()

export default class Address extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare addressable_type?: string
  declare city?: string
  declare co?: string
  declare country?: string
  declare country_de?: string
  declare country_en?: string
  declare kind?: string
  declare kind_de?: string
  declare kind_en?: string
  declare postal?: string
  declare street_name?: string
  declare street_number?: string

  static readonly associations = {
    addressable: {
      type: "single",
    },
    form: {
      type: "single",
    },
  }

  declare addressable?: ApplicationResource
  declare form?: Form
}
