import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"

import Api from "@/api/tag_api"

const API = new Api()

export default class Tag extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare meta: ApplicationResource["meta"] & {
    applied_permissions?: ResourceAppliedPermission[]
  }

  declare background_color?: string
  declare label_de?: string
  declare label_en?: string
  declare no_search?: boolean
  declare order?: number
  declare purpose_de?: string
  declare purpose_en?: string
  declare resource_type?: string
  declare system_purpose?: string
  declare system_tag?: boolean

  static readonly associations = {
    form: {
      type: "single",
    },
  }

  declare form?: Form
}
