import { computed } from "vue"

import { useStore } from "@/store"

import type Domain from "@/models/Domain"

export const $login = computed(() => useStore().state.auth.login)

export const $loginId = computed(() => $login.value?.id)

export const $loginWithAccount = computed(() => {
  if ($login.value && !$login.value.isGuest()) {
    return $login.value
  }

  return undefined
})

export const $user = computed(() => {
  return $login.value?.user
})

export const $userId = computed(() => $user.value?.id)

export const $userType = computed(() => {
  if (
    $user.value?.type == "TeamUser" &&
    !useStore().state.auth.useGlobalRoles
  ) {
    return "AccountUser"
  }

  return $user.value?.type
})

export const $isTeam = computed(() => $userType.value == "TeamUser")

export const $domain = computed(() => {
  if (
    !useStore().state.settings.domains?.enabled ||
    useStore().state.auth.domain == "all"
  ) {
    return undefined
  }

  return useStore().state.auth.domain as Domain
})

export const $domainId = computed(() => {
  return $domain.value?.id
})

export const $hasPermission = (
  perm: string,
  opts: { global?: boolean; domainNeeded?: boolean } = {},
) => {
  return useStore().getters["auth/hasPermission"](perm, opts)
}

export const $hasRole = (role: string) => {
  return useStore().getters["auth/hasRole"](role)
}

export default {
  install: (Vue: any) => {
    Object.defineProperty(Vue.prototype, "$login", {
      get: function get() {
        return $login.value
      },
    })

    Object.defineProperty(Vue.prototype, "$loginId", {
      get: function get(): string | undefined {
        return $loginId.value
      },
    })

    Object.defineProperty(Vue.prototype, "$loginWithAccount", {
      get: function get() {
        return $loginWithAccount.value
      },
    })

    Object.defineProperty(Vue.prototype, "$user", {
      get: function get() {
        return $user.value
      },
    })

    Object.defineProperty(Vue.prototype, "$userId", {
      get: function get() {
        return $userId.value
      },
    })

    Object.defineProperty(Vue.prototype, "$userType", {
      get: function get() {
        return $userType.value
      },
    })

    Object.defineProperty(Vue.prototype, "$domain", {
      get: function get() {
        return $domain.value
      },
    })

    Object.defineProperty(Vue.prototype, "$domainId", {
      get: function get() {
        return $domainId.value
      },
    })

    Object.defineProperty(Vue.prototype, "$isTeam", {
      get: function get() {
        return $isTeam.value
      },
    })

    Vue.prototype.$hasPermission = function (
      perm: string,
      opts: { global?: boolean; domainNeeded?: boolean } = {},
    ) {
      return $hasPermission(perm, opts)
    }

    Vue.prototype.$hasRole = function (role: string) {
      return $hasRole(role)
    }
  },
}
