import ResourceApi from "./resource_api"

import type ContactEmailAddress from "@/models/ContactEmailAddress"
type curResourceProperties = ResourcePropertiesOf<ContactEmailAddress>

export default class ContactEmailAddressApi extends ResourceApi<curResourceProperties> {
  namespace = "contact_email_addresses"
  type = "contact_email_address"

  async verify(
    email: string,
    token: string,
    { user_new_contact_email }: { user_new_contact_email?: boolean } = {},
  ) {
    const path = this.apiPath({ scope: `verify` })

    return this.axios.put(path, {
      data: {
        email,
        token,
        user_new_contact_email,
      },
    })
  }
}
