import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"
import type University from "@/models/University"

import Api from "@/api/university_institute_api"

const API = new Api()

export default class UniversityInstitute extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare hidden?: boolean
  declare key?: string
  declare label_de?: string
  declare label_en?: string
  declare order?: number

  static readonly associations = {
    form: {
      type: "single",
    },
    university: {
      type: "single",
    },
  }

  declare form?: Form
  declare university?: University
}
