import StatisticResource from "@/models/StatisticResource"

import Api from "@/api/users_statistic_api"

const API = new Api()

export default class UsersStatistic extends StatisticResource {
  static readonly api = API
  static readonly type = API.type

  declare created_at?: string
  declare num_total?: number
}
