import { StoreOptions } from "vuex"
import { parse } from "flatted"

import VuexPathify from "@/pathify"

import { default as auth, State as AuthState } from "@/store/auth"
import { default as counts, State as CountsState } from "@/store/counts"
import {
  default as formInputOptions,
  State as FormInputOptionsState,
} from "@/store/form_input_options"
import { default as forms, State as FormsState } from "@/store/forms"
import { default as sidebar, State as SidebarState } from "@/store/sidebar"
import { default as settings, State as SettingsState } from "@/store/settings"
import { default as tags, State as TagsState } from "@/store/tags"
import { default as versions, State as VersionsState } from "@/store/versions"
import modal from "@/store/modal"

export interface RootState {
  auth: AuthState
  counts: CountsState
  forms: FormsState
  formInputOptions: FormInputOptionsState
  settings: SettingsState
  sidebar: SidebarState
  tags: TagsState
  versions: VersionsState
}

const store: StoreOptions<RootState> = {
  modules: {
    auth,
    counts,
    forms,
    formInputOptions,
    modal,
    settings,
    sidebar,
    tags,
    versions,
  },
  plugins: [VuexPathify.plugin],
  mutations: {
    initialiseStore(state) {
      try {
        const versions = localStorage.getItem("cng.versions") as any
        if (versions !== null) {
          state.versions = parse(versions)
        }
      } catch (e: any) {
        console.error("store versions set failed", e)
      }
    },
  },
}

export default store
