import axios from "axios"
import Qs from "qs"

import i18n from "@/i18n"
import config from "@/config"

import type { State as VersionsState } from "@/store/versions"

const instance = axios.create({
  baseURL: config.api_endpoint,
  withCredentials: true,
  paramsSerializer: {
    serialize(params) {
      return Qs.stringify(params, { arrayFormat: "brackets" })
    },
  },
})

const saveVersionsOrReload = async (versions: VersionsState) => {
  const $store = (await import("@/store")).useStore()

  if (!$store.state.versions.frontend) {
    $store.commit("versions/setVersions", versions)
  } else if ($store.state.versions.frontend != versions.frontend) {
    $store.commit("versions/setVersions", { frontend: null })
    window.location.reload()
  }
}

instance.interceptors.request.use((axiosConfig) => {
  if (!axiosConfig.params?.locale) {
    axiosConfig.params ||= {}
    axiosConfig.params.locale = i18n.locale
  }

  return axiosConfig
})

instance.interceptors.response.use((response) => {
  try {
    const versions = response?.data?.meta?.versions
    if (versions) {
      saveVersionsOrReload(versions)
    }
  } catch (e: any) {
    // ignore
  }

  return response
})

export default instance
