import ApplicationResource from "@/models/ApplicationResource"
import type Form from "@/models/Form"

import Api from "@/api/pdf_image_api"

const API = new Api()

export default class PdfImage extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  static readonly fileResources: string[] = ["file"]

  declare meta: ApplicationResource["meta"] & {
    applied_permissions?: (ResourceAppliedPermission | "duplicate")[]
  }

  declare file?: FileResource
  declare internal_label?: string
  declare purpose?: string
  declare purpose_de?: string
  declare purpose_en?: string

  static readonly associations = {
    edit_form: {
      type: "single",
    },
  }

  declare edit_form?: Form
}
