import { GetterTree, MutationTree, ActionTree, Module } from "vuex"

import { RootState } from "@/store/config"

import Login from "@/models/Login"
import type Domain from "@/models/Domain"

export interface State {
  login?: Login | null
  impersonated?: boolean
  useGlobalRoles: boolean
  useDomainRoles: boolean
  domain?: Domain | "all" | null
}

export const STATE: State = {
  login: undefined,
  impersonated: undefined,
  domain: undefined,
  useGlobalRoles: true,
  useDomainRoles: true,
}

export const MUTATIONS: MutationTree<State> = {
  clear(state: State) {
    state.login = undefined
    state.impersonated = undefined
    state.domain = undefined
    state.useGlobalRoles = true
    state.useDomainRoles = true
  },
  deactivateAdminRights(state: State) {
    localStorage.setItem("cng.auth.admin", "false")

    state.useGlobalRoles = false
    state.useDomainRoles = false
  },
  activateAdminRights(state: State) {
    localStorage.removeItem("cng.auth.admin")

    state.useGlobalRoles = true
    state.useDomainRoles = true
  },
  gracefulSignIn(
    state: State,
    { login, impersonated }: { login: Login; impersonated: boolean },
  ) {
    state.login = login
    state.impersonated = impersonated

    if (localStorage.getItem("cng.auth.admin") === "false") {
      state.useGlobalRoles = false
      state.useDomainRoles = false
    }

    if (state.domain == "all") {
      return
    }

    if (login.manage_domains?.length > 0) {
      let found = null
      const domain_id =
        state.domain?.id || localStorage.getItem("cng.auth.domain")

      if (domain_id === "all") {
        state.domain = "all"
        return
      } else if (domain_id) {
        found = login.manage_domains.find((domain) => domain.id == domain_id)
      }

      state.domain = found || login.real_manage_domains[0]
      localStorage.setItem("cng.auth.domain", state.domain?.id || "all")
    } else {
      state.domain = null
      localStorage.removeItem("cng.auth.domain")
    }
  },
  setDomain(state: State, domain: Domain | "all" | null) {
    state.domain = domain

    if (domain) {
      localStorage.setItem(
        "cng.auth.domain",
        domain == "all" ? domain : domain.id,
      )
    } else {
      localStorage.removeItem("cng.auth.domain")
    }
  },
}

export const GETTERS: GetterTree<State, RootState> = {
  hasPermission(state: State) {
    return (
      permission_name: ResourcePermission,
      {
        global,
        domainNeeded,
      }: { global?: boolean; domainNeeded?: boolean } = {},
    ) => {
      if (!state.login) {
        return false
      }

      if (
        (!domainNeeded || !state.domain || state.domain != "all") &&
        state.useGlobalRoles &&
        state.login.hasPermission(permission_name)
      ) {
        return true
      }

      if (
        !state.domain ||
        !state.useDomainRoles ||
        state.domain == "all" ||
        global
      ) {
        return false
      }

      return state.domain.hasPermission(permission_name)
    }
  },
  hasRole(state: State) {
    return (role_name: string) => {
      if (!state.login || !state.useGlobalRoles) {
        return false
      }

      if (state.login.global_role_names?.includes(role_name)) {
        return true
      }

      return false
    }
  },
}

export const ACTIONS: ActionTree<State, RootState> = {
  async checkSelf({ commit }) {
    try {
      const resp = await Login.api.self()
      const login = new Login(resp.data)

      commit("gracefulSignIn", {
        login,
        impersonated: !!resp.meta.impersonated,
      })
    } catch (e: any) {
      if (e.response?.status != 401) {
        console.error(e)
      }
      commit("clear")
    }
  },
}

const Auth: Module<State, RootState> = {
  namespaced: true,
  state: STATE,
  mutations: MUTATIONS,
  getters: GETTERS,
  actions: ACTIONS,
}

export default Auth
