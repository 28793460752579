import ApplicationResource from "./ApplicationResource"
import type FormInput from "@/models/FormInput"
import type Form from "@/models/Form"

import Api from "@/api/form_form_input_association_api"

const API = new Api()

export default class FormFormInputAssociation extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare order?: number

  static readonly associations = {
    form: {
      type: "single",
    },
    form_input: {
      type: "single",
    },
  }

  declare form?: Form
  declare form_input?: FormInput
}
