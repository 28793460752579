import { Module, MutationTree, ActionTree } from "vuex"
import { make } from "vuex-pathify"

import { RootState } from "@/store/config"

import NewsEntry from "@/models/NewsEntry"

export interface State {
  _interval: number
  myNewNewsEntriesCount: number | null
  myNewNewsEntriesInterval: number | null
}

export const STATE: State = {
  _interval: 1000 * 60 * 3,
  myNewNewsEntriesCount: null,
  myNewNewsEntriesInterval: null,
}

export const MUTATIONS: MutationTree<State> = make.mutations(STATE)

export const ACTIONS: ActionTree<State, RootState> = {
  startMyNewNewsEntries({ commit, dispatch, state }) {
    dispatch("fetchMyNewNewsEntries")

    if (state.myNewNewsEntriesInterval) {
      return
    }

    const interval = setInterval(() => {
      dispatch("fetchMyNewNewsEntries")
    }, state._interval)

    commit("myNewNewsEntriesInterval", interval)
  },
  stopMyNewNewsEntries({ commit, state }) {
    if (state.myNewNewsEntriesInterval) {
      clearInterval(state.myNewNewsEntriesInterval)
      commit("myNewNewsEntriesInterval", null)
    }
  },
  async fetchMyNewNewsEntries({ commit }) {
    const count = await NewsEntry.api.allCount({
      collectionParams: {
        filter: {
          for_current_user: "t",
          unseen: "overview",
        },
      },
    })

    commit("myNewNewsEntriesCount", count)
  },
}

const Versions: Module<State, RootState> = {
  namespaced: true,
  mutations: MUTATIONS,
  state: STATE,
  actions: ACTIONS,
}

export default Versions
