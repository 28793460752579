import ApplicationResource from "./ApplicationResource"
import type Form from "./Form"
import PageApi from "@/api/pages_api"

const API = new PageApi()

export default class Page extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare meta: ApplicationResource["meta"] & {
    applied_permissions?: ResourceAppliedPermission[]
  }

  declare kind?: string
  declare link_de?: string
  declare link_en?: string
  declare order: number
  declare place?: string
  declare state?: string
  declare text_de?: string
  declare text_en?: string
  declare title_de?: string
  declare title_en?: string
  declare visible_for?: string[]
  declare visible_for_de?: string[]
  declare visible_for_en?: string[]

  static readonly associations = {
    form: {
      type: "single",
    },
    uploads: {
      type: "many",
    },
  }

  declare form?: Form
}
