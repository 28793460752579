import ResourceApi from "./resource_api"

import type SmtpConfig from "@/models/SmtpConfig"

type curResourceProperties = ResourcePropertiesOf<SmtpConfig>

export default class SmtpConfigApi extends ResourceApi<curResourceProperties> {
  namespace = "smtp_configs"
  type = "smtp_config"
}
