import StatisticResource from "@/models/StatisticResource"

import Api from "@/api/newsletters_statistic_api"

const API = new Api()

export default class NewslettersStatistic extends StatisticResource {
  static readonly api = API
  static readonly type = API.type

  declare created_at?: string
  declare num_by_state?: { [x: string]: number | undefined }
  declare num_recipients?: number
  declare num_total?: number
}
