import Vue from "vue"
import { v4 as uuidv4 } from "uuid"
import type ResourceCollectionContainer from "@/utils/resource_collection_container"

import type Form from "@/models/Form"

import jmespath from "jmespath"

import { assignChanged, deleteAllKeys, assign, dig } from "@/utils/object_utils"

import type ResourceApi from "@/api/resource_api"

import $i18n, { locales } from "@/i18n"

import { useStore } from "@/store"

import { classForType } from "@/utils/model_utils"

type curResourceProperties = ResourcePropertiesOf<ApplicationResource>

export default class ApplicationResource implements ResourceIdentifier {
  declare ["constructor"]: typeof ApplicationResource

  static readonly api: ResourceApi<curResourceProperties> =
    {} as ResourceApi<curResourceProperties>

  meta: { seen: { [x: string]: string } } = { seen: {} }

  relationships: { [x: string]: string | string[] } = {}

  static readonly associations: {
    [x: string]: {
      type: string
      sort?: (a: any, b: any) => number
    }
  } = {}

  readonly id: string
  readonly type: string
  readonly _type: string

  readonly created_at: string
  updated_at = ""

  inited: string[] = []
  readonly associationResourceCache: { [x: string]: ApplicationResource } = {}
  readonly isApplicationResource = true
  readonly $i18n = $i18n

  static readonly fileResources: string[] = []

  readonly errors: { [x: string]: string } = {}
  custom_values?: { [x: string]: unknown }
  custom_values_files?: { [x: string]: FileResource[] }
  custom_values_records?: Array<any>
  disabled_form_fields?: string[]
  enabled_form_fields?: string[]
  resourceContainer?: ResourceCollectionContainer<typeof ApplicationResource> =
    undefined
  isStale = false

  declare form?: Form

  constructor(
    data: Partial<curResourceProperties>,
    associationResourceCache?: { [x: string]: ApplicationResource },
    inited?: string[],
  ) {
    this.id = data.id || uuidv4()
    this.type = data.type || "missing"
    this._type = this.type

    this.created_at = data.created_at || ""

    if (associationResourceCache) {
      this.associationResourceCache = associationResourceCache
    }

    this.associationResourceCache[this.id] = this

    this.initialize({ ...data }, inited)
  }

  initialize(
    data: Partial<curResourceProperties>,
    inited?: string[] | undefined,
  ) {
    this.inited = inited || []
    this.inited.push(this.id)

    this.initAssociations(data)

    if (data.custom_values || data.custom_values_files) {
      for (const key in this) {
        if (!key.startsWith("custom_value_")) {
          continue
        }

        if (ApplicationResource.isFileResource(this[key])) {
          if (data.custom_values_files && !(key in data.custom_values_files)) {
            Vue.delete(this, key)
          }
        } else if (ApplicationResource.isCustomValueRecordKey(key)) {
          Vue.delete(this, key)
        } else {
          if (data.custom_values && !(key.substr(13) in data.custom_values)) {
            Vue.delete(this, key)
          }
        }
      }

      for (const key in data.custom_values) {
        Vue.set(this, `custom_value_${key}`, data.custom_values[key])
      }

      for (const key in data.custom_values_files) {
        Vue.set(this, key, data.custom_values_files[key])
      }

      if (data.custom_values_records) {
        for (const custom_record of data.custom_values_records) {
          const custom_record_key = `custom_values_records_${custom_record.name}`
          const records = ((this as any)[custom_record_key] as Array<any>) ?? []

          if (!custom_record._type) {
            continue
          }

          const klass = classForType(custom_record._type)
          records.push(new klass(custom_record as any))

          Vue.set(this, custom_record_key, records)
        }
      }
    }

    if (this.meta && data.meta) {
      assign(this.meta, data.meta)
      delete data.meta
    }

    assignChanged(this, data)

    this.localInitialize()
  }

  initAssociations(data: Partial<curResourceProperties>) {
    for (const assocName in this.constructor.associations) {
      const assocData = this.constructor.associations[assocName]

      if (assocData.type == "single") {
        this.relationships[assocName] = (data as any)[assocName]?.id

        this.updateSingleAssociation(assocName, data)
      } else {
        this.relationships[assocName] = ((data as any)[assocName] || []).map(
          (el: any) => (el as any).id,
        )

        this.updateArrayAssociation(assocName, data)
      }
    }
  }

  updateAssociationCallback(_opts: {
    attr: string
    resource: ApplicationResource
    action: "create" | "delete"
  }): void {}

  // override in implementing class if you need this callback
  localInitialize() {}

  static isApplicationResource(obj: any): obj is ApplicationResource {
    return obj && obj.isApplicationResource === true
  }

  static isCustomValueRecordKey(name: string) {
    return name.startsWith("custom_values_records_")
  }

  static isFileResource(value: any) {
    if (Array.isArray(value)) {
      value = value[0]
    }

    if (!value || typeof value !== "object") {
      return false
    }

    return "id" in value && "url" in value && "filename" in value
  }

  static isIncluded(obj: {} | Array<{}>): boolean {
    if (Array.isArray(obj)) {
      return obj.length == 0 || this.isIncluded(obj[0])
    } else {
      return !obj || Object.keys(obj).length > 2
    }
  }

  updateSingleAssociation(name: string, data: { [x: string]: any }) {
    if (!(name in data)) {
      if (!(name in this)) {
        Vue.set(this, name, null)
      }

      return
    }

    const newData = data[name]
    delete data[name]

    if (!newData) {
      Vue.set(this, name, null)
    } else {
      if (this.associationResourceCache[newData.id]) {
        if (!(this as any)[name] || (this as any)[name].id != newData.id) {
          Vue.set(this, name, this.associationResourceCache[newData.id])
        }
        if (
          !this.inited.includes(newData.id) &&
          ApplicationResource.isIncluded(newData)
        ) {
          ;(this as any)[name].initialize(newData, this.inited)
        }
      } else if (ApplicationResource.isIncluded(newData)) {
        const klass = classForType(newData._type)
        Vue.set(
          this,
          name,
          new klass(newData, this.associationResourceCache, this.inited),
        )
        // } else {
        //   console.debug(
        //     `Found association but not included: ${this.id}/${this.type}.${name} --> ${newData.id}/${newData.type}`
        //   )
      }
    }
  }

  updateArrayAssociation(name: string, data: { [x: string]: any }) {
    const newData = data[name] as curResourceProperties[] | undefined
    delete data[name]

    if (!newData) {
      if (!(this as any)[name]) {
        Vue.set(this, name, [])
      }

      return
    }

    const newResources: ApplicationResource[] = []

    newData.forEach((newDataData) => {
      if (this.associationResourceCache[newDataData.id]) {
        if (
          !this.inited.includes(newDataData.id) &&
          ApplicationResource.isIncluded(newDataData)
        ) {
          this.associationResourceCache[newDataData.id].initialize(
            newDataData,
            this.inited,
          )
        }

        newResources.push(this.associationResourceCache[newDataData.id])
      } else if (ApplicationResource.isIncluded(newDataData)) {
        const klass = classForType(newDataData._type)
        newResources.push(
          new klass(newDataData, this.associationResourceCache, this.inited),
        )
      }
    })

    if (this.constructor.associations[name].sort) {
      newResources.sort(this.constructor.associations[name].sort)
    }

    Vue.set(this, name, newResources)
  }

  sortAssociation(name: string) {
    if (this.constructor.associations[name]?.sort) {
      ;((this as any)[name] as any).sort(
        this.constructor.associations[name].sort,
      )
    }
  }

  addAssociation(attr: string, data: any) {
    const klass = classForType(data._type || data.type)

    const resource = new klass(data as any, this.associationResourceCache)

    if (
      ApplicationResource.isCustomValueRecordKey(attr) &&
      !Array.isArray((this as any)[attr])
    ) {
      Vue.set(this, attr, [])
    }

    if (Array.isArray((this as any)[attr])) {
      if (!(this as any)[attr].includes(resource)) {
        ;(this as any)[attr].push(resource)

        this.updateAssociationCallback({
          attr,
          resource,
          action: "create",
        })
      }
    } else {
      Vue.set(this, attr, resource)

      this.updateAssociationCallback({
        attr,
        resource,
        action: "create",
      })
    }

    return resource
  }

  addNewAssociation(attr: string, klass: any) {
    if (!klass) {
      return
    }

    return this.addAssociation(attr, { type: klass.type })
  }

  deleteAssociation(attr: string, resource: ApplicationResource) {
    if (Array.isArray((this as any)[attr])) {
      const index = (this as any)[attr]?.indexOf(resource)
      if (index != null && index != undefined && index >= 0) {
        ;(this as any)[attr].splice(index, 1)
        this.updateAssociationCallback({
          attr,
          resource,
          action: "delete",
        })
      }
    } else {
      Vue.delete(this, attr)
      this.updateAssociationCallback({
        attr,
        resource,
        action: "delete",
      })
    }
  }

  forEachAssociatedResource(
    fn: (res: ApplicationResource, key: string) => void,
  ) {
    const checked: string[] = []

    Object.getOwnPropertyNames(this).forEach((key) => {
      const value: any = (this as any)[key]

      if (Array.isArray(value)) {
        value.forEach((val) => {
          if (
            ApplicationResource.isApplicationResource(val) &&
            !checked.includes(val.id)
          ) {
            checked.push(val.id)
            fn(val, key)
          }
        })
      } else {
        if (
          ApplicationResource.isApplicationResource(value) &&
          !checked.includes(value.id)
        ) {
          checked.push(value.id)
          fn(value, key)
        }
      }
    })
  }

  jpQuery(query: string) {
    const replaced = query.replaceAll(
      /\$\$s\.([a-z._]+)/g,
      (_match, setting_path: string) => {
        return dig(useStore().state.settings, setting_path)
      },
    )

    return jmespath.search([this], replaced)
  }

  jpQueryMatches(query: string) {
    return this.jpQuery(query).length > 0
  }

  assignFileResources(data: Partial<curResourceProperties>) {
    this.constructor.fileResources.forEach((prop) => {
      if (Object.prototype.hasOwnProperty.call(data, prop)) {
        Vue.set(this, prop, (data as any)[prop])
      }
    })

    if (data.custom_values_files) {
      for (const key in this) {
        if (
          key.startsWith("custom_value_") &&
          ApplicationResource.isFileResource(this[key]) &&
          data.custom_values_files &&
          !data.custom_values_files[key]
        ) {
          Vue.delete(this, key)
        }
      }

      for (const key in data.custom_values_files) {
        Vue.set(this, key, data.custom_values_files[key])
      }
    }
  }

  extractAttributeErrors(
    errors: APIAttributeInvalidError[],
    { checked }: { checked?: string[] } = {},
  ) {
    checked = checked || []

    if (checked?.includes(this.id)) {
      return
    }
    checked.push(this.id)

    deleteAllKeys(this.errors)

    // we only need attribute_invalid here
    errors = errors.filter((err) => err?.code == "attribute_invalid")

    const ourErrors = errors.filter(
      (err) => !err?.meta?.resource?.id || err.meta?.resource?.id == this.id,
    )
    const otherErrors = errors.filter(
      (err) => err.meta?.resource?.id && err.meta?.resource?.id != this.id,
    )

    ourErrors.forEach((err) => {
      Vue.set(this.errors, err.meta.attribute, err.detail)
    })

    this.forEachAssociatedResource((resource, assoc) => {
      // ignore form assoc because we do not assume the form is to be
      // edited.
      if (assoc != "form") {
        resource.extractAttributeErrors(otherErrors, { checked })
      }
    })
  }

  clearAttributeErrors(): void {
    deleteAllKeys(this.errors)

    this.forEachAssociatedResource((resource, _assoc) => {
      if (Object.keys(resource.errors).length > 0) {
        resource.clearAttributeErrors()
      }
    })
  }

  _fallbackLocale(): string {
    return locales.fallback
  }

  fallbackLocale(): string {
    return this._fallbackLocale()
  }

  _availableLocales(): string[] {
    return locales.allowed
  }

  availableLocales(): string[] {
    return this._availableLocales()
  }

  possibleLocale(locale: string): string {
    if (this.availableLocales().includes(locale)) {
      return locale
    }

    return this.fallbackLocale()
  }

  getLocalized(
    name: string,
    locale?: string,
    { fallback }: { fallback?: boolean } = {},
  ): any {
    fallback ??= true
    locale ??= this.$i18n.locale

    let value = null

    const availableLocales = this.availableLocales()

    if (availableLocales.includes(locale)) {
      value = (this as any)[`${name}_${locale}`]
    }

    if (value || !fallback) {
      return value
    }

    ;[this.fallbackLocale(), ...availableLocales].find((locale) => {
      value = (this as any)[`${name}_${locale}`]
      return value
    })

    if (value) {
      return value
    }

    return (this as any)[name]
  }

  getDate(name: keyof this) {
    if (!this[name]) {
      return new Date(0)
    }

    return new Date(this[name] as any)
  }

  displayLabel(): string {
    return this.getLocalized("label") || this.id
  }

  isSeen(context: string): boolean {
    return !!this.meta.seen[context]
  }

  hasUploads(): boolean {
    return !!this.constructor.associations.uploads
  }

  async markAsSeen(context: string): Promise<boolean> {
    Vue.set(this.meta.seen, context, new Date().toISOString())

    const LastSeenEntry = (await import("@/models/LastSeenEntry")).default
    await LastSeenEntry.api.mark({
      context,
      resourceId: this.id,
      resourceType: this._type,
    })

    return true
  }

  async markAsUnseen(context: string): Promise<boolean> {
    if (this.meta.seen[context]) {
      Vue.delete(this.meta.seen, context)
    }

    const LastSeenEntry = (await import("@/models/LastSeenEntry")).default
    await LastSeenEntry.api.unmark({
      context,
      resourceId: this.id,
      resourceType: this._type,
    })

    return true
  }

  stale(isStale = true) {
    this.isStale = isStale
  }
}
