import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"
import type User from "@/models/User"

import Api from "@/api/domain_api"

const API = new Api()

export default class Domain extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare meta: ApplicationResource["meta"] & {
    permissions?: ResourcePermission[]
  }

  declare name?: string

  hasPermission(permission_name: ResourcePermission) {
    return this.meta.permissions?.includes(permission_name)
  }

  static readonly associations = {
    form: {
      type: "single",
    },
    regular_admins: {
      type: "many",
    },
  }

  declare form?: Form
  declare regular_admins: User[]

  displayLabel() {
    return this.name || this.id
  }
}
