import ApplicationResource from "./ApplicationResource"

import Api from "@/api/export_template_api"

const API = new Api()

export default class ExportTemplate extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare label_de?: string
  declare label_en?: string
  declare resource_type?: string
}
