import ResourceApi from "./resource_api"

import type TextContent from "@/models/TextContent"

type curResourceProperties = ResourcePropertiesOf<TextContent>

export default class TextContentApi extends ResourceApi<curResourceProperties> {
  namespace = "text_contents"
  type = "text_content"
}
