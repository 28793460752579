import ResourceApi from "./resource_api"

import type Poll from "@/models/Poll"
import type PollVote from "@/models/PollVote"

type curResourceProperties = ResourcePropertiesOf<Poll>
type PollVoteResourceProperties = ResourcePropertiesOf<PollVote>

export default class PollApi extends ResourceApi<curResourceProperties> {
  namespace = "polls"
  type = "poll"

  async getBySlug(
    slug: string,
  ): Promise<APIGetResponse<curResourceProperties>> {
    const path = this.apiPath({ scope: `by_slug/${slug}` })
    const res = await this.axios.get(path)

    return {
      data: this.serializer.deserialize(res.data) as curResourceProperties,
      meta: res.data.meta,
    }
  }

  async publish(id: string): Promise<curResourceProperties> {
    const res = await this.axios.put(this.apiPath({ scope: `${id}/publish` }), {
      id,
      type: this.type,
    })

    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async archive(id: string): Promise<curResourceProperties> {
    const res = await this.axios.put(this.apiPath({ scope: `${id}/archive` }), {
      id,
      type: this.type,
    })

    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async apply(
    poll: curResourceProperties,
    { query }: { query?: unknown } = {},
  ): Promise<PollVoteResourceProperties> {
    const res = await this.axios.post(
      this.apiPath({ scope: `${poll.id}/apply` }),
      {
        id: poll.id,
        type: poll.type,
      },
      { params: query },
    )

    return this.serializer.deserialize(res.data) as PollVoteResourceProperties
  }

  async addForm(id: string, formIds: string[]) {
    await this.axios.put(this.apiPath({ scope: `${id}/add_form` }), {
      id,
      type: this.type,
      data: {
        form_ids: formIds,
      },
    })

    return true
  }
}
