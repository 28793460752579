import ApplicationResource from "./ApplicationResource"
import type MailPicture from "@/models/MailPicture"

import Api from "@/api/newsletter_section_api"

const API = new Api()

export default class NewsletterSection extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare content_de?: string
  declare content_en?: string
  declare main_point?: boolean
  declare order?: number
  declare title_de?: string
  declare title_en?: string

  static readonly associations = {
    mail_pictures: {
      type: "many",
    },
  }

  declare mail_pictures: MailPicture[]
}
