import { Module, MutationTree } from "vuex"
import { stringify } from "flatted"

import { RootState } from "@/store/config"

export interface State {
  frontend?: string
  backend?: string
}

export const STATE: State = {
  frontend: undefined,
  backend: undefined,
}

export const MUTATIONS: MutationTree<State> = {
  setVersions(state: State, versions: State) {
    Object.assign(state, versions)

    localStorage.setItem("cng.versions", stringify(versions))
  },
}

const Versions: Module<State, RootState> = {
  namespaced: true,
  mutations: MUTATIONS,
  state: STATE,
}

export default Versions
