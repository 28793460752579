import Vue from "vue"
import { debounce } from "throttle-debounce"

const forceAllUpdateNow = (vm: Vue) => {
  if (process.env.NODE_ENV !== "production") {
    console.debug("Forcing all update")
  }
  vm.$forceUpdate()
  vm.$children.forEach((childVm) => forceAllUpdateNow(childVm))
}

export const forceAllUpdate = debounce(200, forceAllUpdateNow)
