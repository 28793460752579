import ResourceApi from "./resource_api"

import type Signature from "@/models/Signature"

type curResourceProperties = ResourcePropertiesOf<Signature>

export default class SignatureApi extends ResourceApi<curResourceProperties> {
  namespace = "signatures"
  type = "signature"
}
