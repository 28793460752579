import ResourceApi from "./resource_api"

import type MailTask from "@/models/MailTask"
type curResourceProperties = ResourcePropertiesOf<MailTask>

export default class MailTaskApi extends ResourceApi<curResourceProperties> {
  namespace = "mail_tasks"
  type = "mail_task"

  async cancel(id: string): Promise<curResourceProperties> {
    const res = await this.axios.put(this.apiPath({ scope: `${id}/cancel` }), {
      id,
      type: this.type,
    })

    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async reschedule(id: string, sendAt: string): Promise<curResourceProperties> {
    const res = await this.axios.put(
      this.apiPath({ scope: `${id}/reschedule` }),
      {
        id,
        type: this.type,
        data: {
          send_at: sendAt,
        },
      },
    )

    return this.serializer.deserialize(res.data) as curResourceProperties
  }
}
