import ApplicationResource from "./ApplicationResource"

import Api from "@/api/salutation_style_api"

const API = new Api()

export default class SalutationStyle extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare name_de?: string
  declare name_en?: string
}
