import ApplicationResource from "./ApplicationResource"

import Api from "@/api/upload_api"

const API = new Api()

export default class Upload extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  static readonly fileResources: string[] = ["file"]
}
