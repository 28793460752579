import ApplicationResource from "@/models/ApplicationResource"
import type Form from "@/models/Form"

import Api from "@/api/pdf_layout_api"

const API = new Api()

export default class PdfLayout extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare meta: ApplicationResource["meta"] & {
    applied_permissions?: (ResourceAppliedPermission | "duplicate")[]
  }

  declare global?: boolean
  declare internal_label?: string
  declare label?: string
  declare locales?: string[]
  declare main_locale?: string
  declare purpose_de?: string
  declare purpose_en?: string
  declare template?: string

  static readonly associations = {
    edit_form: {
      type: "single",
    },
  }

  declare edit_form?: Form

  fallbackLocale(): string {
    return this.main_locale || ""
  }

  availableLocales(): string[] {
    return this.locales || []
  }
}
