import { v4 as uuidv4 } from "uuid"

import { deleteIf } from "@/utils/array_utils"

import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"
import type SearchFilter from "@/models/SearchFilter"

import Api from "@/api/search_api"

const API = new Api()

export default class Search extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare name?: string

  static readonly associations = {
    filters: {
      type: "many",
    },
    form: {
      type: "single",
    },
  }

  declare filters: SearchFilter[]
  declare form?: Form

  generateFilterParam() {
    const ret: { [x: string]: unknown } = {}

    this.filters.forEach((filter) => {
      const value = this.extractFilterParam(filter.value)
      if (filter.name && value !== null && value !== undefined) {
        ret[filter.name] = value
      }
    })

    return ret
  }

  setFromFilterParam(
    param: { [x: string]: unknown },
    opts: { clear?: boolean } = {},
  ): void {
    for (const key in param) {
      const value = param[key]

      const existing = this.filters.find((filter) => filter.name == key)

      if (existing) {
        existing.value = value
      } else {
        this.addAssociation("filters", {
          id: uuidv4(),
          type: "search_filter",
          name: key,
          value,
        })
      }
    }

    if (opts["clear"]) {
      deleteIf(
        this.filters,
        (filter) => !filter.name || !(filter.name in param),
      )
    }
  }

  extractFilterParam(value: unknown): unknown {
    if (Array.isArray(value)) {
      return value.map((val) => this.extractFilterParam(val))
    }

    if (ApplicationResource.isApplicationResource(value)) {
      return (value as ApplicationResource).id
    }

    return value
  }
}
