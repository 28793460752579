import ResourceApi from "./resource_api"

import type Address from "@/models/Address"

type curResourceProperties = ResourcePropertiesOf<Address>

export default class AddressApi extends ResourceApi<curResourceProperties> {
  namespace = "addresses"
  type = "address"
}
