import ResourceApi from "./resource_api"
import { serialize as objectToFormData } from "object-to-formdata"

import type User from "@/models/User"
type curResourceProperties = ResourcePropertiesOf<User>

export default class UserApi extends ResourceApi<curResourceProperties> {
  namespace = "users"
  type = "user"

  validate(id: string, action: string) {
    return this.axios.get(this.apiPath({ scope: `${id}/validate/${action}` }))
  }

  async byNewsletterAccessToken(
    id: string,
    {
      collectionParams,
    }: { collectionParams?: APIResourceCollectionParams } = {},
  ) {
    const params = {}
    if (collectionParams) {
      this.addCollectionParamsToObject(collectionParams, params)
    }

    const res = await this.axios.get(
      this.apiPath({ scope: `by_newsletter_access_token/${id}` }),
      { params },
    )

    return {
      data: this.serializer.deserialize(res.data) as curResourceProperties,
      meta: res.data.meta,
    }
  }

  async sendMail(
    subject: string,
    body: string,
    {
      attachments,
      domain,
      language,
      salutationStyleId,
      filter,
      scopeFilter,
      preview,
      previewType,
      signatureId,
      sendAt,
      tagsToAdd,
    }: {
      attachments?: FileResource[]
      domain?: string
      language?: string
      salutationStyleId?: string
      filter?: { [x: string]: any }
      scopeFilter?: { [x: string]: any }
      preview?: boolean
      previewType?: "mail" | "html"
      signatureId?: string
      sendAt?: string
      tagsToAdd?: string[]
    } = {},
  ) {
    const path = this.apiPath({
      scope: preview ? `/send_mail_preview` : `/send_mail`,
    })

    const data = {
      domain_id: domain,
      only_html: preview && previewType == "html" ? true : undefined,
      data: {
        subject,
        body,
        language,
        salutation_style_id: salutationStyleId,
        signature_id: signatureId,
        attachments: undefined as undefined | File[],
        send_at: sendAt,
        recipient_tags_to_add_ids: tagsToAdd,
      },
    }

    if (attachments) {
      const presentAttachments: File[] = attachments
        .filter((attachment) => attachment.file || attachment.id)
        .map((attachment) => (attachment.file as File) || attachment.id)

      if (presentAttachments.length > 0) {
        data.data.attachments = presentAttachments
      }
    }

    const post = this.axios.post(path, objectToFormData(data), {
      params: { filter, scope_filter: scopeFilter },
    })

    if (!preview || previewType != "html") {
      return post
    }

    return (await post).data
  }

  async addEmailAddress(id: string, email: string) {
    const path = this.apiPath({ scope: `${id}/add_email_address` })

    return this.axios.put(path, {
      data: {
        type: this.type,
        id,
        email,
      },
    })
  }

  async addVerifiedEmailAddress(
    id: string,
    email: string,
    { user_new_contact_email }: { user_new_contact_email?: boolean } = {},
  ) {
    const path = this.apiPath({ scope: `${id}/add_verified_email_address` })

    return this.axios.put(path, {
      data: {
        type: this.type,
        id,
        email,
        user_new_contact_email,
      },
    })
  }

  async removeLocalLogin(id: string, email: string) {
    const path = this.apiPath({ scope: `${id}/remove_local_login` })

    const res = await this.axios.delete(path, {
      data: {
        data: { email },
      },
    })

    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async addLocalLogin(id: string, email: string) {
    const path = this.apiPath({ scope: `${id}/add_local_login` })

    const res = await this.axios.put(path, {
      data: { email },
    })

    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async confirmData(id: string) {
    const path = this.apiPath({ scope: `${id}/confirm_data` })

    const res = await this.axios.put(path)

    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  activate(id: string) {
    const path = this.apiPath({ scope: `${id}/activate` })

    return this.axios.put(path)
  }

  deactivate(id: string, { reactivateAt }: { reactivateAt?: string } = {}) {
    const path = this.apiPath({ scope: `${id}/deactivate` })

    return this.axios.put(path, { data: { reactivate_at: reactivateAt } })
  }

  async interpolationDoc(
    id?: string,
    opts: {
      withUser?: boolean
      withGlobal?: boolean
      withSignature?: boolean
      withCustom?: string[]
    } = {},
  ) {
    const path = this.apiPath({
      scope: id ? `${id}/interpolation_doc` : "interpolation_doc",
    })

    const params = {} as Record<string, unknown>
    params.with_global = opts.withGlobal
    params.with_signature = opts.withSignature
    params.with_user = opts.withUser
    params.with_custom = opts.withCustom

    return (await this.axios.get(path, { params })).data?.data
  }
}
