import ApplicationResource from "./ApplicationResource"
import type MailPicture from "@/models/MailPicture"

export default class NewsletterSectionView extends ApplicationResource {
  declare content?: string
  declare main_point?: boolean
  declare section_id?: string
  declare title?: string
  declare title_position?: string

  static readonly associations = {
    mail_pictures: {
      type: "many",
    },
  }

  declare mail_pictures: MailPicture[]
}
