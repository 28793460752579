import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"

import Api from "@/api/main_category_api"

const API = new Api()

export default class MainCategory extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  static readonly fileResources: string[] = ["icon"]

  declare icon?: FileResource
  declare label_de?: string
  declare label_en?: string
  declare order?: number

  static readonly associations = {
    form: {
      type: "single",
    },
  }

  declare form?: Form
}
