import StatisticsApi from "./statistics_api"

import type EventsStatistic from "@/models/EventsStatistic"

type curResourceProperties = ResourcePropertiesOf<EventsStatistic>

export default class EventsStatisticApi extends StatisticsApi<curResourceProperties> {
  namespace = "events_statistics"
  type = "events_statistic"
}
