import ResourceApi from "./resource_api"

import type LastSeenEntry from "@/models/LastSeenEntry"
type curResourceProperties = ResourcePropertiesOf<LastSeenEntry>

export default class LastSeenEntryApi extends ResourceApi<curResourceProperties> {
  namespace = "last_seen_entries"
  type = "last_seen_entry"

  async mark({
    context,
    resourceId,
    resourceType,
  }: {
    context?: string
    resourceId?: string
    resourceType?: string
  } = {}): Promise<boolean> {
    await this.axios.put(this.apiPath({ scope: "mark" }), {
      context,
      resource_id: resourceId,
      resource_type: resourceType,
    })

    return true
  }

  async unmark({
    context,
    resourceId,
    resourceType,
  }: {
    context?: string
    resourceId?: string
    resourceType?: string
  } = {}): Promise<boolean> {
    await this.axios.put(this.apiPath({ scope: "unmark" }), {
      context,
      resource_id: resourceId,
      resource_type: resourceType,
    })

    return true
  }
}
