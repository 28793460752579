import Vue, { watch } from "vue"
import VueI18n, { DateTimeFormat } from "vue-i18n"

Vue.use(VueI18n)

import { Locale } from "vue-i18n/types"

import api from "@/api/translations"

import deepmerge from "deepmerge"

import { dig, setKey, deleteKey, deleteAllKeys } from "@/utils/object_utils"
import { deferred } from "@/utils/promise_utils"

const allDeTranslationsCache = require
  .context("@/locales", true, /\.de\.ts$/, "lazy")
  .keys()

export const locales: {
  allowed: string[]
  default: string
  fallback: string
  loaded: { [x: string]: string[] }
} = Vue.observable({
  allowed: ["de", "en"],
  default: "de",
  fallback: "de",
  loaded: {},
})

const defaultNamespace = "cng-desktop"

const formatNamespace = (
  namespace: boolean | string | null | undefined,
): string | null => {
  if (namespace === true) {
    return defaultNamespace
  } else if (namespace) {
    return namespace
  } else {
    return null
  }
}

const prefixNamespace = (
  thing: string,
  namespace: boolean | string | null | undefined,
): string => {
  const formattedNamespace = formatNamespace(namespace)
  if (formattedNamespace) {
    return `${formattedNamespace}.${thing}`
  } else {
    return thing
  }
}

const extractNamespace = (key: string) => {
  const firstDotIndex = key.indexOf(".")

  if (firstDotIndex && key.match(/^([A-Z]|global\.)/)) {
    return [key.substring(0, firstDotIndex), key.slice(firstDotIndex + 1)]
  }

  return [null, key]
}

const localeIsLoaded = (locale: string, namespace: string) => {
  if (!locales.loaded[locale]) {
    return false
  }

  return (
    locales.loaded[locale].includes(namespace) ||
    locales.loaded[locale].includes("_all")
  )
}

const dateFormats: DateTimeFormat = {
  date: {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZone: "Europe/Berlin",
  },
  dateTime: {
    hour: "2-digit",
    minute: "2-digit",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZone: "Europe/Berlin",
  },
  dateFull: {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
    timeZone: "Europe/Berlin",
  },
  full: {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    timeZone: "Europe/Berlin",
  },
  time: {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Europe/Berlin",
  },
  fullTime: {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "Europe/Berlin",
  },
}

export const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale: locales.default,
  dateTimeFormats: {
    de: dateFormats,
    en: dateFormats,
  },
  missing(locale, key, _vm, values) {
    const [namespace, keyWoNamespace] = extractNamespace(key)

    const namespaceToLoad = namespace || "global"
    if (!localeIsLoaded(locale, namespaceToLoad)) {
      loadLocaleAsync(locale, namespaceToLoad)
    }

    if (namespaceToLoad !== "global" && !localeIsLoaded(locale, "global")) {
      loadLocaleAsync(locale, "global")
    }

    if (
      locale != locales.fallback &&
      !localeIsLoaded(locales.fallback, namespaceToLoad)
    ) {
      loadLocaleAsync(locales.fallback, namespaceToLoad)
    }

    if (values.length && (values[0] === null || values[0] === undefined)) {
      // might happen if empty locale was first value, simply delete it
      values = values.slice(1)
    }

    if (locale != locales.fallback) {
      if (values.length == 0) {
        values.push(locales.fallback, { _originalLocale: locale })
      } else if (values.length == 1) {
        if (values[0] == locale) {
          values[0] = locales.fallback
          values.push({ _originalLocale: locale })
        } else {
          values[0]._originalLocale = locale
          values.unshift(locales.fallback)
        }
      } else {
        if (values[0] === locale) {
          values[0] = locales.fallback
        } else {
          values.unshift(locales.fallback)
        }
        values[values.length - 1]._originalLocale = locale
      }

      return i18n.t(key, ...values) as string | void
    }

    if (!namespace || namespace !== "global") {
      if (values.length == 2 && values[1]._originalLocale) {
        const originalLocale = values[1]._originalLocale
        delete values[1]._originalLocale

        return i18n.t(`global.${keyWoNamespace}`, originalLocale, values[1]) as
          | string
          | void
      }

      return i18n.t(`global.${keyWoNamespace}`, ...values) as string | void
    }

    if (process.env.NODE_ENV === "production") {
      return ""
    } else {
      return key
    }
  },
}) as VueI18n

export const useI18n = (namespace: string) => {
  loadLocaleAsync(i18n.locale, namespace)

  watch(
    () => i18n.locale,
    () => loadLocaleAsync(i18n.locale, namespace),
  )

  return {
    $i18n: i18n,
    $curT: (key: string, ...rest: any[]) => {
      return i18n.t(`${namespace}.${key}`, ...rest)
    },
    $curTString: (key: string, ...rest: any[]) => {
      return i18n.t(`${namespace}.${key}`, ...rest).toString()
    },
    $curTc: (key: string, ...rest: any[]) => {
      return i18n.tc(`${namespace}.${key}`, ...rest)
    },
    $curTe: (key: string, ...rest: any[]) => {
      return i18n.te(`${namespace}.${key}`, ...rest)
    },
    $curTPath: (key: string) => {
      return `${namespace}.${key}`
    },
    reloadI18nData: () => {
      return loadLocaleAsync(i18n.locale, namespace)
    },
  }
}

type FallbackMessages = { [x: string]: string | FallbackMessages }
export const fallbackMessages: FallbackMessages = {}

type RemoteMessages = { [x: string]: string | RemoteMessages }
export const remoteMessages: RemoteMessages = {}

type Messages = { [x: string]: string | Messages }
export const messages: Messages = {}

locales.allowed.forEach((locale) => {
  Vue.set(fallbackMessages, locale, {})
  Vue.set(remoteMessages, locale, {})
  Vue.set(messages, locale, {})
})

const assign = (obj1: {}, obj2: {}) => {
  Object.entries(obj2).forEach(([key, value]) => {
    Vue.set(obj1, key, value)
  })
}

function setI18nLocale(locale: Locale) {
  i18n.locale = locale
  const html = document.querySelector("html")
  if (html) html.setAttribute("lang", locale)
}

export async function fetchLocale(
  locale: Locale,
  namespaces: string | string[] = "global",
  lookupScope: "custom" | "default" | "remote" | "fallback" = "fallback",
): Promise<{ [x: string]: any } | undefined> {
  if (namespaces == "_all") {
    const allNamespaces = []
    for (const key of allDeTranslationsCache) {
      const [, , namespace] = key.match(
        /(\.\/)?([^.]+)\.de\.ts/,
      ) as RegExpMatchArray

      allNamespaces.push(namespace)
    }

    return await fetchLocale(locale, allNamespaces, lookupScope)
  }

  if (lookupScope === "fallback") {
    const ret: { [x: string]: unknown } = {}

    for (const namespace of namespaces) {
      try {
        ret[namespace] = (
          await import(`@/locales/${namespace}.${locale}`)
        ).default
      } catch {
        if (process.env.NODE_ENV !== "production") {
          // eslint-disable-next-line
          console.debug(`No local translations for "${locale}/${namespace}"`)
        }
      }
    }

    return ret
  }

  try {
    return await fetchRemoteTranslations(locale, namespaces, { lookupScope })
  } catch (e: any) {
    // eslint-disable-next-line
    console.warn(
      `Fetching remote translations for "${locale}/${namespaces}" failed`,
      e,
    )
  }
}

const loadLocaleAsyncCache: { [x: string]: Set<string> } = {}
const loadLocaleAsyncDeferreds: Array<{ resolve: Function }> = []
let asyncTo: number | undefined = undefined

export function loadLocaleAsync(
  locale: Locale,
  namespaces: string | string[],
): Promise<unknown> {
  if (!loadLocaleAsyncCache[locale]) {
    loadLocaleAsyncCache[locale] = new Set()
  }

  const sizeBefore = loadLocaleAsyncCache[locale].size

  if (Array.isArray(namespaces)) {
    namespaces.forEach((namespace) => {
      loadLocaleAsyncCache[locale].add(namespace)
    })
  } else {
    loadLocaleAsyncCache[locale].add(namespaces)
  }

  const def = deferred()
  loadLocaleAsyncDeferreds.push(def)

  if (sizeBefore != loadLocaleAsyncCache[locale].size) {
    clearTimeout(asyncTo)
    asyncTo = window.setTimeout(async () => {
      const curDeferreds = [...loadLocaleAsyncDeferreds]
      loadLocaleAsyncDeferreds.splice(0)

      const curAsyncCache = Object.assign({}, loadLocaleAsyncCache)
      deleteAllKeys(loadLocaleAsyncCache)

      for (const locale in curAsyncCache) {
        const namespaces = curAsyncCache[locale]
        await loadLocale(locale, Array.from(namespaces))
      }

      curDeferreds.forEach((curDef) => curDef.resolve())
    }, 500)
  }

  return def.promise
}

export async function loadLocale(
  locale: Locale,
  namespaces: string | string[],
): Promise<boolean> {
  if (!locales.loaded[locale]) {
    locales.loaded[locale] = []
  }

  if (!Array.isArray(namespaces)) {
    namespaces = [namespaces]
  }

  namespaces = namespaces.filter(
    (namespace) => !localeIsLoaded(locale, namespace),
  )

  if (namespaces.length == 0) {
    return false
  }

  locales.loaded[locale].push(...namespaces)

  const fallbackTranslations = fetchLocale(locale, namespaces, "fallback")
  const remoteTranslations = fetchLocale(locale, namespaces, "remote")

  const awaitedFallbackTranslations = await fallbackTranslations
  const awaitedRemoteTranslations = await remoteTranslations

  if (awaitedFallbackTranslations) {
    assign(fallbackMessages[locale], awaitedFallbackTranslations)
  }

  if (awaitedRemoteTranslations) {
    assign(remoteMessages[locale], awaitedRemoteTranslations)
  }

  if (awaitedRemoteTranslations || awaitedFallbackTranslations) {
    assign(
      messages[locale],
      deepmerge(fallbackMessages[locale] as any, remoteMessages[locale] as any),
    )

    i18n.setLocaleMessage(locale, messages[locale] as any)
  }

  return !!(awaitedRemoteTranslations || awaitedFallbackTranslations)
}

export async function fetchRemoteTranslations(
  locale: string,
  keys: string | string[],
  {
    lookupScope,
    namespace,
  }: {
    lookupScope?: "custom" | "default" | "remote" | "fallback"
    namespace?: boolean | string
  } = {},
) {
  if (namespace === undefined) {
    namespace = true
  }
  if (!Array.isArray(keys)) {
    keys = [keys]
  }
  keys = keys.map((key) => prefixNamespace(key, namespace))

  const resp = await api.get({
    locales: [locale],
    keys,
    lookupScope: lookupScope == "remote" ? undefined : lookupScope,
  })

  if (resp[locale]) {
    const formattedNamespace = formatNamespace(namespace)
    if (formattedNamespace) {
      return resp[locale][formattedNamespace]
    } else {
      return resp[locale]
    }
  }
}

export async function updateTranslation(
  locale: Locale,
  key: string,
  value: string,
  { namespace }: { namespace?: boolean | string } = {},
) {
  if (namespace === undefined) {
    namespace = true
  }
  const sendObj: any = {}

  prefixNamespace(key, namespace)
    .split(".")
    .reduce((acc, cur, i, arr) => {
      acc[cur] = i == arr.length - 1 ? value : {}
      return acc[cur]
    }, sendObj)

  api.update({ [locale]: sendObj })

  if (formatNamespace(namespace) === defaultNamespace) {
    setKey(messages[locale], key, value)
    setKey(remoteMessages[locale], key, value)

    i18n.setLocaleMessage(locale, messages[locale] as {})
  }
}

export async function removeTranslation(
  locale: Locale,
  key: string,
  { namespace }: { namespace?: boolean | string } = {},
) {
  if (namespace === undefined) {
    namespace = true
  }
  api.remove([`${locale}.${prefixNamespace(key, namespace)}`])

  if (formatNamespace(namespace) === defaultNamespace) {
    deleteKey(remoteMessages[locale], key)
    setKey(messages[locale], key, dig(fallbackMessages[locale], key))

    i18n.setLocaleMessage(locale, messages[locale] as {})
  }
}

export function setLocale(locale: Locale) {
  if (!locales.allowed.includes(locale)) {
    throw new Error(`Locale ${locale} invalid or not allowed`)
  }

  setI18nLocale(locale)
  return loadLocaleAsync(locale, "global")
}

export function setDefaultLocale(locale: Locale) {
  if (!locales.allowed.includes(locale)) {
    throw new Error(`Locale ${locale} invalid or not allowed`)
  }

  locales.default = locale
}

export function setAllowedLocales(newAllowedLocales: Locale[]) {
  locales.allowed = newAllowedLocales

  locales.allowed.forEach((locale) => {
    if (!fallbackMessages[locale]) {
      Vue.set(fallbackMessages, locale, {})
    }
    if (!remoteMessages[locale]) {
      Vue.set(remoteMessages, locale, {})
    }
    if (!messages[locale]) {
      Vue.set(messages, locale, {})
    }
  })
}

export default i18n
