import Vue from "vue"
import { MutationTree, ActionTree, Module } from "vuex"

import { deleteAllKeys } from "@/utils/object_utils"
import { deferred } from "@/utils/promise_utils"

import { RootState } from "@/store/config"

import Form from "@/models/Form"
import type ApplicationResource from "@/models/ApplicationResource"

export interface State {
  forms: Record<string, Form | undefined>
  promises: Record<string, Promise<Form | undefined> | undefined>
  requested: Set<string>
  lastChecked: Record<string, Date>
}

export const STATE: State = {
  forms: {},
  promises: {},
  requested: new Set(),
  lastChecked: {},
}

export const MUTATIONS: MutationTree<State> = {
  clear(state: State) {
    deleteAllKeys(state.forms)
  },
  setForm(state: State, { key, form }: { key: string; form: Form }) {
    Vue.set(state.forms, key, form)
    if (form && key != form.id) {
      Vue.set(state.forms, form.id, state.forms[key])
    }
  },
  setPromise(
    state: State,
    { key, prom }: { key: string; prom: Promise<Form> },
  ) {
    Vue.set(state.promises, key, prom)
  },
  addRequested(state: State, key: string) {
    state.requested.add(key)
  },
  removeRequested(state: State, key: string) {
    state.requested.delete(key)
  },
  setLastChecked(state: State, { key, date }: { key: string; date: Date }) {
    Vue.set(state.lastChecked, key, date)
  },
}

export const ACTIONS: ActionTree<State, RootState> = {
  async fetchForm(
    { commit, dispatch, state },
    key: string,
  ): Promise<Form | undefined> {
    if (state.requested.has(key)) {
      if (state.forms[key]) {
        dispatch("checkForm", state.forms[key])

        return state.forms[key]
      } else {
        return state.promises[key]!
      }
    }

    commit("addRequested", key)

    const def = deferred()
    commit("setPromise", { key, prom: def.promise })

    try {
      const form = await dispatch("getForm", key)
      def.resolve(form)

      commit("setLastChecked", { key, date: new Date() })

      return form
    } catch (e: any) {
      commit("removeRequested", key)
      def.reject(e)
      return undefined
    }
  },
  async getForm({ commit }, key: string) {
    const resp = await Form.api.getByName(key)

    const form = new Form(resp.data)

    commit("setForm", {
      key,
      form,
    })

    return form
  },
  async fetchResourceForm(
    { dispatch },
    { resource, query }: { resource?: ApplicationResource; query?: {} },
  ) {
    if (!resource) {
      return
    }

    return dispatch("fetchClassForm", {
      klass: resource.constructor,
      resourceId: resource.id,
      formId: resource.relationships?.form,
      query,
    })
  },
  async fetchClassForm(
    { commit, state },
    {
      klass,
      resourceId,
      formId,
      query,
      formParam,
    }: {
      klass: typeof ApplicationResource
      resourceId: string
      formId?: string
      query?: {}
      formParam?: string
    },
  ) {
    if (!formId || typeof formId != "string") {
      return
    }

    if (!formParam) {
      formParam = "form"
    }

    if (state.requested.has(formId)) {
      if (state.forms[formId]) {
        return state.forms[formId]
      } else {
        return state.promises[formId]!
      }
    }

    commit("addRequested", formId)

    const def = deferred()
    commit("setPromise", { formId, prom: def.promise })

    try {
      const resourceData = await klass.api.get(resourceId, {
        collectionParams: { includeAllForms: formParam },
        query,
      })

      const fetchedResource = new klass(resourceData.data) as any

      commit("setForm", {
        key: fetchedResource[formParam]!.id,
        form: fetchedResource[formParam],
      })

      def.resolve(fetchedResource[formParam])
      return fetchedResource[formParam]
    } catch (e: any) {
      commit("removeRequested", formId)
      def.reject(e)
      return undefined
    }
  },
  async checkForm({ commit, dispatch, state }, form: Form) {
    const key = form.name

    if (
      !key ||
      (state.lastChecked[key] &&
        state.lastChecked[key].getTime() + 20000 > new Date().getTime())
    ) {
      return
    }

    commit("setLastChecked", { key, date: new Date() })

    const date = await Form.api.getLastUpdated(form.id)

    if (!date || date.getTime() == new Date(form.updated_at).getTime()) {
      return
    }

    commit("removeRequested", key)
    return dispatch("fetchForm", key)
  },
}

const Forms: Module<State, RootState> = {
  namespaced: true,
  state: STATE,
  mutations: MUTATIONS,
  actions: ACTIONS,
}

export default Forms
