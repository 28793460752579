import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"
import type NewsChannel from "@/models/NewsChannel"

import Api from "@/api/news_entry_api"

const API = new Api()

export default class NewsEntry extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare meta: ApplicationResource["meta"] & {
    applied_permissions?: ResourceAppliedPermission[]
    publicly_visible?: boolean
  }

  declare author_de?: string
  declare author_en?: string
  declare body_de?: string
  declare body_en?: string
  declare published_on?: string
  declare subject_de?: string
  declare subject_en?: string
  declare visible_end_at?: string
  declare visible_start_at?: string

  static readonly associations = {
    form: {
      type: "single",
    },
    news_channels: {
      type: "many",
    },
  }

  declare form?: Form
  declare news_channels: NewsChannel[]
}
