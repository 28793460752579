import ResourceApi from "./resource_api"

import type PollVote from "@/models/PollVote"
type curResourceProperties = ResourcePropertiesOf<PollVote>

export default class PollVoteApi extends ResourceApi<curResourceProperties> {
  namespace = "poll_votes"
  type = "poll_vote"

  async handIn(id: string, { query }: { query?: unknown } = {}) {
    const res = await this.axios.put(
      this.apiPath({ scope: `${id}/hand_in` }),
      {
        type: this.type,
        id,
      },
      { params: query },
    )
    return this.serializer.deserialize(res.data) as curResourceProperties
  }

  async verify(id: string, token: string, { query }: { query?: unknown } = {}) {
    await this.axios.put(
      this.apiPath({ scope: `${id}/verify` }),
      {
        type: this.type,
        id,
        data: {
          token,
        },
      },
      { params: query },
    )

    return true
  }
}
