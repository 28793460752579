import ApplicationResource from "./ApplicationResource"
import type Form from "@/models/Form"
import type FormFormInputAssociation from "@/models/FormFormInputAssociation"
import type FormInputOption from "@/models/FormInputOption"
import type Tag from "@/models/Tag"

import FormInputApi from "@/api/form_input_api"

const API = new FormInputApi()

export default class FormInput extends ApplicationResource {
  static readonly api = API
  static readonly type = API.type

  declare meta: ApplicationResource["meta"] & {
    applied_permissions?: (ResourceAppliedPermission | "duplicate")[]
  }

  declare autocomplete?: string
  declare create_sub_resources_if?: string
  declare date_max?: number
  declare date_max_offset_days?: number
  declare date_min?: number
  declare date_min_offset_days?: number
  declare default?: string
  declare destroy_sub_resource_if?: string
  declare destroy_sub_resources_if?: string
  declare disabled?: boolean
  declare disabled_if?: string
  declare display_only?: boolean
  declare enabled?: boolean
  declare end_at?: string
  declare fetch_options?: boolean
  declare flat_display?: boolean
  declare hidden?: boolean
  declare hidden_if?: string
  declare hidden_sub_resource_if?: string
  declare hidden_sub_resource_input_if?: { [x: string]: string }
  declare hide_label?: boolean
  declare hide_sub_form_label?: boolean
  declare hint?: string
  declare hint_de?: string
  declare hint_en?: string
  declare internal_label?: string
  declare kind?: string
  declare label?: string
  declare label_de?: string
  declare label_en?: string
  declare localizable?: boolean
  declare maxlength?: number
  declare mirrored?: boolean
  declare multiple?: boolean
  declare name: string
  declare num_max?: number
  declare num_min?: number
  declare order?: number
  declare place: string
  declare place_labels?: {
    key: string
    label: string
  }[]
  declare placeholder_de?: string
  declare placeholder_en?: string
  declare radio?: boolean
  declare relationship_allow_create?: boolean
  declare relationship_scope?: { [x: string]: string }
  declare relationship_type?: string
  declare relationship_only_id?: boolean
  declare required?: boolean
  declare required_if?: string
  declare resource_type?: string
  declare slow_edit?: boolean
  declare start_at?: string
  declare sub_form_create_button_label_de?: string
  declare sub_form_create_button_label_en?: string
  declare sub_form_label_de?: string
  declare sub_form_label_en?: string
  declare unique?: boolean
  declare virtual_options?: boolean
  declare virtual_options_parent?: boolean

  // private _cacheOptions?: boolean

  static readonly associations = {
    blank_tags: {
      type: "many",
    },
    edit_form: {
      type: "single",
    },
    form: {
      type: "single",
    },
    form_input_options: {
      type: "many",
      sort: (a: FormInputOption, b: FormInputOption) =>
        (a.order || 0) - (b.order || 0),
    },
    form_form_input_associations: {
      type: "many",
      sort: (a: FormFormInputAssociation, b: FormFormInputAssociation) =>
        (a.order || 0) - (b.order || 0),
    },
    mirror_form_input: {
      type: "single",
    },
    present_tags: {
      type: "many",
    },
    real_form_input_options: {
      type: "many",
      sort: (a: FormInputOption, b: FormInputOption) =>
        (a.order || 0) - (b.order || 0),
    },
    sub_form: {
      type: "single",
    },
  }

  declare blank_tags: Tag[]
  declare edit_form?: Form
  declare form?: Form
  declare form_form_input_associations: FormFormInputAssociation[]
  declare form_input_options: FormInputOption[]
  declare mirror_form_input?: FormInput
  declare present_tags: Tag[]
  declare real_form_input_options: FormInputOption[]
  declare sub_form?: Form

  uploadProgress = 0

  localInitialize() {
    if (!this.place) {
      this.place = "default"
    }

    if (
      !this.virtual_options &&
      Array.isArray(this.real_form_input_options) &&
      ApplicationResource.isIncluded(this.real_form_input_options) &&
      this.form_input_options.length == 0
    ) {
      this.form_input_options.push(...this.real_form_input_options)
    }
  }

  localizedName(locale: string) {
    if (!this.localizable) {
      return this.name
    }

    return `${this.name}_${locale}`
  }

  createSubResourcesAttr(resource: ApplicationResource): boolean {
    if (this.kind != "sub_form" || !this.name) {
      return false
    }

    if (
      !this.multiple &&
      ((resource as any)[this.name] as ApplicationResource[])
    ) {
      return false
    }

    if (
      this.num_max &&
      ((resource as any)[this.name] as ApplicationResource[])?.length >=
        this.num_max
    ) {
      return false
    }

    if (!this.create_sub_resources_if) {
      return true
    }

    return resource.jpQueryMatches(this.create_sub_resources_if)
  }

  destroySubResourcesAttr(
    parentResource: ApplicationResource,
    resourceToDestroy: ApplicationResource,
  ): boolean {
    if (this.kind != "sub_form" || !this.name) {
      return false
    }

    const curValue = (parentResource as any)[this.name] as
      | ApplicationResource
      | ApplicationResource[]

    if (
      this.requiredAttr(parentResource) &&
      (!this.multiple || !Array.isArray(curValue) || curValue.length <= 1)
    ) {
      return false
    }

    if (
      this.num_min &&
      (!Array.isArray(curValue) || curValue.length <= this.num_min)
    ) {
      return false
    }

    return (
      (!this.destroy_sub_resources_if ||
        parentResource.jpQueryMatches(this.destroy_sub_resources_if)) &&
      (!this.destroy_sub_resource_if ||
        resourceToDestroy.jpQueryMatches(this.destroy_sub_resource_if))
    )
  }

  disabledAttr(resource: ApplicationResource, locale?: string): boolean {
    if (this.disabled) {
      return true
    }

    if (this.disabled_if && resource.jpQueryMatches(this.disabled_if)) {
      return true
    }

    if (
      resource.enabled_form_fields != null &&
      (this.localizable
        ? locale &&
          !resource.enabled_form_fields.includes(`${this.name}_${locale}`)
        : !resource.enabled_form_fields.includes(this.name))
    ) {
      return true
    }

    if (
      resource.disabled_form_fields &&
      (this.localizable
        ? locale &&
          resource.disabled_form_fields.includes(`${this.name}_${locale}`)
        : resource.disabled_form_fields.includes(this.name))
    ) {
      return true
    }

    return false
  }

  hiddenAttr(resource: ApplicationResource): boolean {
    return !!(
      this.hidden ||
      (this.enabled !== undefined && !this.enabled) ||
      (this.hidden_if && resource.jpQueryMatches(this.hidden_if))
    )
  }

  hiddenResource(resource: ApplicationResource): boolean {
    return !!(
      this.hidden_sub_resource_if &&
      resource.jpQueryMatches(this.hidden_sub_resource_if)
    )
  }

  hiddenSubAttr(name: string, resource: ApplicationResource): boolean {
    if (!this.hidden_sub_resource_input_if?.[name]) {
      return false
    }

    return !!resource.jpQueryMatches(this.hidden_sub_resource_input_if[name])
  }

  requiredAttr(resource: ApplicationResource): boolean {
    return !!(
      this.required ||
      (this.required_if && resource.jpQueryMatches(this.required_if))
    )
  }

  cacheOptions(): boolean {
    return false

    // if (this.virtual_options_parent) {
    //   return false
    // }
    //
    // if (!this.relationship_scope) {
    //   return true
    // }
    //
    // if (!this._cacheOptions) {
    //   this._cacheOptions = this.cacheScope(this.relationship_scope)
    // }
    //
    // return this._cacheOptions
  }

  cacheScope(scope: Record<string, unknown>): boolean {
    for (const key in scope) {
      const value = scope[key]

      if (typeof value == "string") {
        if (value.startsWith("@.")) {
          return false
        }
      } else if (typeof value == "object") {
        return this.cacheScope(value as any)
      }
    }

    return true
  }
}
