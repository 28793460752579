import ResourceApi from "./resource_api"

import type Search from "@/models/Search"
type curResourceProperties = ResourcePropertiesOf<Search>

export default class SearchApi extends ResourceApi<curResourceProperties> {
  namespace = "searches"
  type = "search"

  async replace(
    id: string,
    replace_with_id: string,
  ): Promise<{ [x: string]: unknown }> {
    const ret = await this.axios.put(
      this.apiPath({ scope: `${id}/replace/${replace_with_id}` }),
      {
        id,
        type: this.type,
      },
    )

    return ret.data.data
  }
}
